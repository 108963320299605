import React, { useState,useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { fetch_member_communication_data } from '../../../services/mtp/Service';
import { LoaderLoader } from '../../utils/CommonLibrary';

function MemberCommunicationsTable() {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedChannel, setSelectedChannel] = useState('All');
    const [selectedStatus, setSelectedStatus] = useState('All');
    const [communicationData, setCommunicationData] = useState([]);
    const [communicationMessage, setCommunicationMessage] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [activeTooltip, setActiveTooltip] = useState(null);


useEffect(() => {
        fetchMemberCommunicationData();
},[]);


const fetchMemberCommunicationData = async () => {
    try {
        setIsLoading(true); // show loader
        let response = await fetch_member_communication_data();
        if(response.data.code === 200){
            setCommunicationData(response.data.data || []);
        }
        setIsLoading(false); // hide loader
    } catch (error) {
      console.error(error);
    }
};

const replaceVariables = (content) => {
    if (!content) return "";

    // URL regex for better link detection
    const urlRegex = /(?:(?:https?:\/\/)?(?:www\.)?)?[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?/gi;
    
    const commonTLDs = ['com', 'org', 'net', 'edu', 'gov', 'mil', 'io', 'co', 'ai', 'app', 'dev', 'uk', 'us', 'eu', 'in', 'de', 'fr'];

     
     // First handle line breaks
     content = content.replace(/\\n/g, "<br />");

     // Split content by HTML tags and process each part
     const parts = content.split(/(<[^>]*>)/);
     content = parts.map(part => {
         // If it's an HTML tag, return it unchanged
         if (part.startsWith('<') && part.endsWith('>')) {
             return part;
         }
         // If part contains Arabic/Urdu, wrap it in RTL div
         if (/[\u0600-\u06FF\uFB50-\uFDFF\uFE70-\uFEFF]/.test(part)) {
             return `<div class="tw-text-right" dir="rtl" style="text-align: right;">${part}</div>`;
         }
         // If it's non-empty text without Arabic/Urdu, wrap it in regular div
         if (part.trim()) {
             return `<div>${part}</div>`;
         }
         // Return empty parts unchanged
         return part;
     }).join('');

     console.log(content,'content')

    return (
      content
        // Handle line breaks first
        // .replace(/\\n/g, "<br />")

        // Bold: *text* (not preceded or followed by space)
        .replace(/\*([^*]+)\*/g, "<strong>$1</strong>")

        // Italic: _text_ (not preceded or followed by space)
        .replace(/(?<!\s)_(.*?)_(?!\s)/g, "<em>$1</em>")

        // Strikethrough: ~text~ (not preceded or followed by space)
        .replace(/(?<!\s)~(.*?)~(?!\s)/g, "<del>$1</del>")

        // Monospace: ```text```
        .replace(/```(.*?)```/g, "<code>$1</code>")

        // Single line code: `text`
        .replace(/`(.*?)`/g, "<code>$1</code>")

        // Enhanced URL detection and replacement
        .replace(urlRegex, (url) => {
          // Check if this matches common URL patterns
          const isLikelyURL = commonTLDs.some(tld => 
            url.toLowerCase().includes(`.${tld}`) &&
            url.match(/^[^@\s]+\.[^\s]+$/) // Exclude email addresses
          );

          if (!isLikelyURL) return url;

          // Add protocol if missing
          let fullUrl = url;
          if (!url.match(/^https?:\/\//i)) {
            fullUrl = 'https://' + url;
          }

          // Validate URL
          try {
            new URL(fullUrl);
            return `<a href="${fullUrl}" class="tw-text-blue-600 hover:tw-underline" target="_blank" rel="noopener noreferrer">${url}</a>`;
          } catch {
            return url;
          }
        })

        // WhatsApp style quotes: > at start of line
        .replace(
          /(^|\n)&gt; ?([^\n]*)/g,
          '$1<div class="tw-border-l-4 tw-border-gray-300 tw-pl-3 tw-my-1 tw-text-gray-600">$2</div>'
        )

        // Lists: - or â€¢ at start of line
        .replace(
          /(^|\n)[-â€¢] ?([^\n]*)/g,
          '$1<div class="tw-flex tw-gap-2 tw-my-1">â€¢<span>$2</span></div>'
        )

        // Handle multiple underscores or asterisks in a single word
        .replace(/(\*{2}|_{2})(.*?)\1/g, "<strong>$2</strong>")
        .replace(/(\*{3}|_{3})(.*?)\1/g, "<strong><em>$2</em></strong>")

        // Fix any potentially broken HTML from nested formatting
        .replace(/<(\/?)(strong|em|del|code)>/g, "<$1$2>")
    );
  };

console.log(communicationData)
    const columns = [
        {
            name: 'Member ID',
            selector: row => row.membershipNo,
            sortable: true,
        },
        {
            name: 'Mobile',
            selector: row => row.mobile,
            sortable: true,
        },
        {
            name: 'Member Name',
            selector: row => row.memberName,
            sortable: true,
        },
        {
            name: 'Communication Message',
            selector: row =>  <p
            className='card-text'
            dangerouslySetInnerHTML={{ __html: replaceVariables(row.communination) }}
        ></p>,
            sortable: true,
        },
        {
            name: 'Sent Date',
            selector: row => row.DeliveredDate,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            cell: (row, index) => {
                console.log(row)
                return (
                <div className='tw-relative tw-flex tw-items-center tw-gap-1'>
                    <span style={{ color: '#000000' }}>
                        {row.status}
                    </span>
                    {row.status === "Undelivered" && (
                        <div className="tw-relative tw-inline-block">
                            <div className="tw-group">
                                <i className="bi bi-info-circle tw-cursor-pointer tw-text-xs tw-mt-2"></i>
                                <div className={`tw-invisible group-hover:tw-visible tw-absolute tw-z-[9999999999] tw-w-60 tw-h-60 tw-overflow-y-auto tw-left-3 ${index === 0 || index === 1 ? 'tw-top-3' : 'tw-bottom-3'} tw-bg-white tw-rounded-lg tw-border tw-shadow-lg tw-p-4`}>
                                    <div className="tw-relative">
                                        <p className="tw-text-sm tw-font-bold tw-text-red-600 tw-mb-2">
                                            ERROR: {row.errorCode}
                                        </p>
                                        <p 
                                        // dangerouslySetInnerHTML={{ __html: row.possible_causes }} 
                                        className="tw-text-sm tw-text-gray-600 tw-mb-3">
                                            {row.description}
                                        </p>
                                        <div className="tw-flex tw-flex-col tw-gap-2">
                                            <span className='tw-text-sm'>
                                                <a
                                                    href={`https://www.twilio.com/docs/api/errors/${row.errorCode}`}
                                                    className="tw-text-blue-500 hover:tw-underline tw-text-sm tw-mr-1"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    onClick={(e) => e.stopPropagation()}
                                                >
                                                    View Error Details
                                                </a>
                                                about Error code <strong>{row.errorCode}</strong>
                                            </span>
                                            <span className='tw-text-sm'>
                                                Possible Solution: {row.solution}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )},
        },
        {
            name: 'Sent By',
            selector: row => row.senderBy,
            sortable: true,
        },
        {
            name: 'Role',
            selector: row => row.senderRole,
            sortable: true,
        },
        {
            name: 'Communication Channel',
            selector: row => row.communicationType,
            sortable: true,
        },
       
    ];
    const data = communicationData;

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
                minHeight: '60px',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedChannel('All');
        setSelectedStatus('All');
    };
    
    const filteredData = data.filter(item => {
        const matchesSearchQuery = 
            item.membershipNo.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||  // Matching memberId
            item.memberName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.communination.toLowerCase().includes(searchQuery.toLowerCase());
    
        const matchesChannel = selectedChannel === 'All' || item.communicationType === selectedChannel;
        const matchesStatus = selectedStatus === 'All' || item.status === selectedStatus;
    
        return matchesSearchQuery && matchesChannel && matchesStatus;
    });

    return (
        <>
            <LoaderLoader isLoading={isLoading} />
           
            <div className='container-fluid'>
                
                <div className='row mb-3'>
                    
                    <div className='col-md d-flex px-1'>
                    <div className="form-group" style={{width: "100%"}}>
                        <input
                            type="text"
                            placeholder="Search By Member Name or Member ID"
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                            className="form-control input-field-ui ps-5"
                        />
                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md-3 px-1'> 
                        <select
                            value={selectedStatus}
                            onChange={e => setSelectedStatus(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Status--</option>
                            <option value="Sent">Sent</option>
                            <option value="Read">Read</option>
                            <option value="Delivered">Delivered</option>
                            <option value="Undelivered">Undelivered</option>
                        </select>
                    </div>
                    <div className='col-md-3 px-1'>
                        <select
                            value={selectedChannel}
                            onChange={e => setSelectedChannel(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Communication--</option>
                            <option value="Whatsapp">Whatsapp</option>
                            {/* <option value="SMS">SMS</option>
                            <option value="Push Notification">Push Notification</option>
                            <option value="Email">Email</option> */}
                        </select>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary btn-primary clear-search-btn'>Clear</button>
                    </div>
                </div>
            </div>
            {communicationData === 0 ? <div>{communicationMessage}</div> :
            (<>
            <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
            />
            </>
            )}
        </>
    );
}

export default MemberCommunicationsTable;
