import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './WhatsAppTemplates.css';
import WATemplateLibrary from './component/WATemplateLibrary';
import WAActive from './component/WAActive';
import WADeleted from './component/WADeleted';
import { fetchTemplates } from '../../services/mtp/Service.js';
import { useNavigate, useLocation,useSearchParams,Link } from 'react-router-dom';
import WAPendingRequest from './component/WAPendingRequest.jsx';
import { CustomLoader } from '../utils/CommonLibrary.js';


function WhatsAppTemplates() {
  
  // Define state for selectedIndex
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastSync, setLastSync] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(8);

  const [perPage, setPerPage] = useState(10); // Rows per page
  const [page, setPage] = useState(1); // Current page
  const [currentRecordsRange, setCurrentRecordsRange] = useState("");
  const [accessRole, setAccessRole] = useState(false);
  const [accessAction, setAccessAction] = useState(false);
  const [createAction, setCreateAction] = useState(false);
  

  
 


  let allTabs = [
    { label: "Template Library", id: 1, isVisible: true },
    { label: "Pending Review", id: 3, isVisible: null },
    { label: "Active", id: 2, isVisible: true }, // Hidden for this user
    { label: "Deleted", id: 4, isVisible: true }
];

const visibleTabs = allTabs
    .map(tab => ({
      ...tab,
      isVisible: tab.id === 3 ? accessRole : tab.isVisible
    }))
    .filter(tab => tab.isVisible)
    .map((tab, index) => ({
      ...tab,
      position: index // Add position to keep track of actual visible position
    }));


    useEffect(() => {
      const tabParam = searchParams.get('tab');
      
      if (tabParam) {
        const tabId = parseInt(tabParam, 10);
        
        // Find the tab in our visible tabs array
        const targetTab = visibleTabs.find(tab => tab.id === tabId);
        
        if (targetTab) {
          // If we found the tab, use its position as the index
          setSelectedIndex(targetTab.position);
        } else {
          // If tab not found, go to first tab
          const defaultTabId = visibleTabs[0]?.id;
          navigate(`${location.pathname}?tab=${defaultTabId}`, { replace: true });
          setSelectedIndex(0);
        }
      } else {
        // No tab parameter, go to first tab
        const defaultTabId = visibleTabs[0]?.id;
        navigate(`${location.pathname}?tab=${defaultTabId}`, { replace: true });
        setSelectedIndex(0);
      }
    }, [searchParams, visibleTabs, navigate, location.pathname]);

 // Filter tabs based on visibility
// const visibleTabs = allTabs.filter((tab) => tab.isVisible);

 // State to track the selected tab ID
//  const [selectedTabId, setSelectedTabId] = useState(visibleTabs[0]?.id || null);

  // Find the selected tab's index in the visible list
  // const selectedIndexTemp = visibleTabs.findIndex((tab) => tab.id === selectedTabId);

  const handleTabSelect = (index) => {
    const selectedTab = visibleTabs[index];
    if (selectedTab) {
      setSelectedIndex(index);
      setCurrentPage(1);
      navigate(`${location.pathname}?tab=${selectedTab.id}`, { replace: true });
    }
  };

//   const handleTabSelect = (index) => {
//     setSelectedIndex(index);
//     setCurrentPage(1);
//     navigate(`${location.pathname}?tab=${index}`, { replace: true });
//     // setTemplates('');
// };

  // const getTemplates = async (templateStatus, page, limit = recordsPerPage) => {
  //   setLoading(true);
  //   setError(null);
  //   try {
  //     const response = await fetchTemplates(templateStatus, page, limit);
  //     setTemplates(response.data.data);
  //     setAccessRole(response.data.accessTemp);
  //     setLastSync('');
  //   } catch (err) {
  //     setError('Error fetching templates. Please try again.');
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // Add this at the top of your component
// const tabToStatusMap = {
//   1: 'whatsapp_list',    // Template Library
//   2: 'active',           // Active
//   3: 'pending',          // Pending Review
//   4: 'deleted'           // Deleted
// };

// Then update your getTemplates function to use consistent parameters
const getTemplates = async (templateStatus, page, limit = recordsPerPage) => {
  setLoading(true);
  setError(null);
  try {
    const response = await fetchTemplates(templateStatus, page, limit);
    setTemplates(response.data.data);
    setAccessRole(response.data.accessTemp);
    setAccessAction(response.data.accessAction);
    setCreateAction(response.data.createAction);
    setLastSync('');
    
    // Update current records range
    const start = (page - 1) * limit + 1;
    const end = Math.min(page * limit, response.data.data.totalRecords);
    if (start > 0 && end > 0) {
      setCurrentRecordsRange(`${start}-${end}`);
    }
  } catch (err) {
    setError('Error fetching templates. Please try again.');
  } finally {
    setLoading(false);
  }
};

useEffect(() => {
  const selectedTab = visibleTabs[selectedIndex];
  if (selectedTab) {
    const fetchData = async () => {
      switch (selectedTab.id) {
        case 1: // Template Library
          await getTemplates('active', page, perPage);
          break;
        case 2: // Active
          await getTemplates('active2', page, perPage);
          break;
        case 3: // Pending Review
          await getTemplates('active3', page, perPage);
          break;
        case 4: // Deleted
          await getTemplates('inactive', page, perPage);
          break;
        default:
          await getTemplates('active', page, perPage);
      }
    };
    fetchData();
  }
}, [selectedIndex, page, perPage]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  
  const handleLimitChange = (newLimit) => {
    setPerPage(newLimit);
    setPage(1);
  };

 
  // const handlePageChange = (page) => {
  //   setCurrentPage(page);
  //   if (selectedIndex === 1 || selectedIndex === 0) {
  //     getTemplates('active', page, recordsPerPage);
  //   }else if (selectedIndex === 2) {
  //     getTemplates('active2', page, recordsPerPage);
  //   }else if (selectedIndex === 3) {
  //     getTemplates('active3', page, recordsPerPage);
  //   } else {
  //     getTemplates('inactive', page, recordsPerPage);
  //   }
  // };

  
  // const handleLimitChange = (newLimit) => {
  //   setRecordsPerPage(newLimit);
  //   setCurrentPage(1);
  //   if (selectedIndex === 1 || selectedIndex === 0) {
  //     getTemplates('active', 1, newLimit);
  //   } else if (selectedIndex === 2)  {
  //     getTemplates('active2', 1, newLimit);
  //   }else if (selectedIndex === 3)  {
  //     getTemplates('active3', 1, newLimit);
  //   }else{
  //     getTemplates('inactive', 1, newLimit);
  //   }
  // };

  // const totalPages = Math.ceil(templates.totalRecords / perPage);

  // useEffect(() => {
  //   const params = new URLSearchParams(location.search);
  //   const tab = params.get('tab');
  //   const newIndex = tab ? parseInt(tab, 10) : 1;
  //   setSelectedIndex(newIndex);
  //   console.log("selectedIndex", newIndex); // Log the new index here, if needed.
  //   //navigate(`${location.pathname}?tab=${newIndex}`, { replace: true });
  // }, [location.search]);
  
  // useEffect(() => {
  //   if (selectedIndex === 1 || selectedIndex === 0) {
  //     const start = (page - 1) * perPage + 1;
  //     const end = Math.min(page * perPage, templates.totalRecords);
  //     if (start > 0 && end > 0) {
  //         setCurrentRecordsRange(`${start}-${end}`);
  //     }
  //     getTemplates('active', page, perPage);

  //   } else if (selectedIndex === 2) {
  //     getTemplates('active2', currentPage);
  //   } else if (selectedIndex === 3) {
  //     getTemplates('active3', currentPage);
  //   } else {
  //     getTemplates('inactive', currentPage);
  //   }
  // }, [selectedIndex,page,perPage,accessRole]);

    // Calculate the current records range (e.g., "1-10 of 100")
    const totalPages = Math.ceil(templates.totalRecords / perPage);

    // useEffect(() => {
    //   const start = (page - 1) * perPage + 1;
    //   const end = Math.min(start + perPage - 1, templates.totalRecords);
    //   setCurrentRecordsRange(`${start}-${end}`);
    // }, [page, perPage, templates.totalRecords]);

  return (
    <>  
      <section className='WhatsAppTemplates'>
        <div className="container-fluid px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
            <div className="col-md">
              <h1 className="main-title">WhatsApp Templates</h1>
            </div>
            {createAction && (
            <div className="col-md-auto text-end"><Link className="btn btn-primary add-new-program-btn" to="/create-new-template">+ Create New Template</Link></div>
            )}
          </div>

          <div className='reports-programs mb-3'>
            <div className="row align-items-center">
              <div className="col-md-auto">
                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/8lv9k/3jq47sc8s4.png" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
              </div>
              <div className="col-md">
                <p className="mb-3">WhatsApp Templates are pre-approved by Facebook/Meta for compliant communication. The Template Library offers customizable templates for submission. The 'Active' tab shows submitted templates with their status, while the 'Deleted' tab lists removed templates.</p>
              </div>
            </div>
          </div>

          <div className='whatsapp-templates'>
            <div className="row">
              <div className="col-md-12">
                {/* <Tabs selectedIndex={selectedIndex} onSelect={index => handleTabSelect(index)}> */}
                <Tabs selectedIndex={selectedIndex} onSelect={handleTabSelect}>
                  <TabList>
                  {visibleTabs.map((tab, index) => (
                    <Tab key={tab.id}>{tab.label}</Tab>
                ))}
                  </TabList>
                  
                  <TabPanel>
                  {templates.templateData && (
                    <WATemplateLibrary 
                      templates={templates}
                      currentPage={currentPage}
                      recordsPerPage={recordsPerPage}
                      onPageChange={handlePageChange}
                      onLimitChange={handleLimitChange}
                      loading={loading}
                      setPerPage={setPerPage}
                      perPage={perPage}
                      setPage={setPage}
                      page={page}
                      totalPages={totalPages}
                      accessAction={accessAction}
                    />
                  )}
                  </TabPanel>
                {accessRole && (
                  <TabPanel>
                    <div className="table-responsive">
                    {templates.templateData && (
                      <WAPendingRequest 
                        templates={templates} 
                        getTemplates={getTemplates} 
                        lastSync={lastSync}
                        createAction={createAction}
                      />
                    )}
                    </div>
                  </TabPanel>
                )}
                 
                  
                  <TabPanel>
                    <div className="table-responsive">
                    {templates.templateData && (
                      <WAActive 
                        templates={templates} 
                        getTemplates={getTemplates} 
                        lastSync={lastSync}
                        createAction={createAction}
                      />
                    )}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="table-responsive">
                      {templates.templateData  && <WADeleted templates={templates} />}
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WhatsAppTemplates;