import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import { members_age_group_conversion_graph } from '../../../services/met/Service';
import { formatDate } from '../../utils/CommonLibrary';

// Initialize exporting module
exporting(Highcharts);

const AgeGroupConversionBar = ({startDate,endDate,agentId}) => {
  // Define your data
  const months = ['Below 18', '18-35', '35-45', '45-55', 'Above 55'];

  const [loadingCount, setLoadingCount] = useState(0);
  const [graphData, setGraphData] = useState({});
  const [ageBooked, setAgeBooked] = useState([]);
  
  useEffect(() => {
    membersAgeGroupConversionGraph();    
}, [agentId,startDate,endDate]);

  const membersAgeGroupConversionGraph = async () => {
    try {
        let sdate = formatDate(startDate);
        let edate = formatDate(endDate);
        let response = await members_age_group_conversion_graph(sdate,edate,agentId);
        if(response.data.code === 200){
            setGraphData(response.data.data[0]);
            let data = response.data.data[0];
            const transformedData = [
              data.below18,
              data.age18to35,
              data.age35to45,
              data.age45to55,
              data.above55,
            ];
            if(data.below18 === 0 && data.age18to35 === 0 && data.age35to45 === 0 && data.age45to55 === 0 && data.above55 === 0){
              setLoadingCount(0)
            }else{
              setLoadingCount(response.data.data.length)
            }
            setAgeBooked(transformedData);
         
           
        }
    } catch (error) {
        console.log(error);
    }
}

//const callsBooked = [3, 17, 25, 35, 20];

  const yAxisJson = {
    title: {
      text: null
    }
  };

  const tooltipJson = {
    shared: true,
    valueSuffix: ''
  };

  const seriesData = [{
    name: 'Age Group Conversion',
    data: ageBooked,
    color: '#5D70E3'
  }, ];

  const optionsMCI7 = {
    chart: {
      type: 'column',
      height: 200,
      spacing: [20, 0, 0, 0],
      options3d: {
        enabled: true,
        alpha: 0,
        beta: 10,
        depth: 50,
        viewDistance: 25
      }
    },
    title: {
      text: null,
      align: 'left',
      style: {
        fontSize: '20px',
        fontWeight: '600',
        color: '#333',
        fontFamily: "IBM Plex Sans, sans-serif"
      }
    },
    xAxis: {
      categories: months,
      gridLineWidth: 0
    },
    yAxis: yAxisJson,
    tooltip: tooltipJson,
    plotOptions: {
      column: {
        borderRadius: 5,
        pointPadding: 0.2,
        groupPadding: 0.1,
        stacking: null, // Use null for side-by-side columns
        dataLabels: {
          enabled: true,
          style: {
            textOutline: 'none'
          }
        }
      }
    },
    series: seriesData,
    credits: {
      enabled: false
    },
   exporting: {
         enabled: true,
         buttons: {
           contextButton: {
             enabled: false
           }
         }
       },
    legend: {
      enabled: false
    }
  };

  return (
    <>
    {loadingCount > 0 ? (
      <div>
        <HighchartsReact
          highcharts={Highcharts}
          options={optionsMCI7}
        />
    </div>
     ) : (
      <div className="emty-column">
        <h5 className="emty-title-graph">There are no data to display</h5>
    </div>
  )} 
     </>
  );
};

export default AgeGroupConversionBar;
