import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import highcharts3d from 'highcharts/highcharts-3d';

// Initialize exporting module
exporting(Highcharts);
highcharts3d(Highcharts);

const SortedWhatsAppAnalyticsBar2 = (props) => { 
  const { dataMonth } = props;
  const { columnName: careNavigators, dataValue: membersManaged, color: colorCode, displayName: axisName, subvalue: valueSuffix } = dataMonth;

  // Create combined data for sorting
  const dataWithValues = membersManaged.map((value, index) => ({
    value: Number(value) || 0,
    original: value,
    navigator: careNavigators[index]
  }));

  // Sort the data by value in descending order
  const sortedData = dataWithValues.sort((a, b) => b.value - a.value);

  // Separate the sorted data back into arrays
  const sortedNavigators = sortedData.map(item => item.navigator);
  const processedData = sortedData.map(item => ({
    y: item.value,
    original: item.original
  }));

  const yAxisJson = {
    title: {
      text: "   "
    }
  };

  const tooltipJson = {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
      '<td style="padding:0"><b>{point.original}</b></td></tr>',
    footerFormat: '</table>',
    shared: true,
    useHTML: true
  };

  const columnData = [{
    name: axisName,
    data: processedData,
    color: colorCode
  }];

  const optionsCN1 = {
    chart: {
      type: "column",
      backgroundColor: 'transparent',
      height: 260,
      options3d: {
        enabled: true,
        alpha: 0,
        beta: 15,
        depth: 50,
        viewDistance: 0
      },
      marginTop: [34]
    },
    title: {
      text: "   ",
    },
    subtitle: null,
    xAxis: {
      categories: sortedNavigators,
      gridLineWidth: 0
    },
    yAxis: yAxisJson,
    tooltip: tooltipJson,
    plotOptions: {
      column: {
        borderRadius: 5,
        pointPadding: 0.2,
        groupPadding: 0.1,
        stacking: null,
        dataLabels: {
          enabled: true,
          formatter: function() {
            return this.point.original;
          },
          style: {
            textOutline: 'none'
          }
        }
      }
    },
    series: columnData,
    credits: {
      enabled: false
    },
    legend: {
      enabled: true
    },
    exporting: {
          enabled: true,
          buttons: {
            contextButton: {
              enabled: false
            }
          }
        },
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={optionsCN1}
      />
    </div>
  );
};

export default SortedWhatsAppAnalyticsBar2;