import common_axios from "./commonAxios";
// import { encryptDataNew } from "../../pages/utils/CommonLibrary";

let baseURL = common_axios.defaults.baseURL;

export const getCareNavigators = (supervisor) => {
    const params = { 'supervisor': supervisor }
    var dataPass = common_axios.post(baseURL + 'medengage/care_navigator_list', params);
    return dataPass;
};

// export const getCareNavigatorsDetails = (startDateNew,endDateNew,program,selectedValue) => {
//     const params = { 'startRange': startDateNew, 'endRange': endDateNew, 'program': program , 'condition': selectedValue }
//     var dataPass = common_axios.post( baseURL + 'medengage/care_navigator_list_details',params);
//     return dataPass;
// };

export const getCareNavigatorsDetails = (searchStr, startDateNew, endDateNew, programId, conditionId, pageNumber, perPage, sortColumn, sortDirection, download) => {
    const params = { 'searchStr': searchStr, 'startRange': startDateNew, 'endRange': endDateNew, 'program': programId, 'condition': conditionId, 'pageNumber': pageNumber, 'perPage': perPage, 'sortColumn': sortColumn, 'sortDirection': sortDirection, 'download': download }
    var dataPass = common_axios.post(baseURL + 'medengage/care_navigator_list_details', params)
    return dataPass;
};

export const fetch_call_history_list = (searchStr, cnId, callStatus, startDate, endDate, pageNumber, perPage, sortColumn, sortDirection, download) => {
    const params = { 'searchStr': searchStr, 'cnId': cnId, 'callStatus': callStatus, 'startRange': startDate, 'endRange': endDate, 'pageNumber': pageNumber, 'perPage': perPage, 'sortColumn': sortColumn, 'sortDirection': sortDirection, 'download': download }
    var dataPass = common_axios.post(baseURL + 'medengage/fetch_call_history_list', params)
    return dataPass;
};


export const fetch_audio_play_list = (callId) => {
    let params = { 'callId': callId }
    let response = common_axios.post('medengage/fetch_audio_play_list', params);
    return response;
}

export const careNavigatorList = () => {
    var dataPass = common_axios.get(baseURL + 'medengage/care_transfer_list');
    return dataPass;
};

export const fetchAppointment = (startDate, endDate) => {
    const params = { 'startDate': startDate, 'endDate': endDate }
    var dataPass = common_axios.post(baseURL + 'medengage/fetch_appointment_transfer', params);
    return dataPass;
};

export const fetchMembersProgress = (startDate, endDate) => {
    const params = { 'startDate': startDate, 'endDate': endDate }
    var dataPass = common_axios.post(baseURL + 'medengage/fetch_members_progress_data', params);
    return dataPass;
};

export const fetchMembersProgressV2 = (searchStr, cnId, startDate, endDate, pageNumber, perPage, sortColumn, sortDirection, download) => {
    const params = { 'searchStr': searchStr, 'cnId': cnId, 'startDate': startDate, 'endDate': endDate, 'pageNumber': pageNumber, 'perPage': perPage, 'sortColumn': sortColumn, 'sortDirection': sortDirection, 'download': download }
    var dataPass = common_axios.post(baseURL + 'medengage/fetch_members_progress_data_v2', params);
    return dataPass;
};


export const saveAppointment = (callId, newDateTime, newCareId) => {
    const params = { 'callId': callId, 'newDateTime': newDateTime, 'newCareId': newCareId }
    var dataPass = common_axios.post(baseURL + 'medengage/save_appointment', params);
    return dataPass;
};

export const fetchAppointmentSlots = (callId, newDateTime, newCareId) => {
    const params = { 'newDateTime': newDateTime, 'newCareId': newCareId }
    var dataPass = common_axios.post(baseURL + 'medengage/fetch_available_slot', params);
    return dataPass;
};

export const fetchCareUserList = () => {
    var dataPass = common_axios.get(baseURL + 'medengage/fetch_care_data');
    return dataPass;
};

export const updateVisibilityCare = (careId, visibility) => {
    const params = { 'careId': careId, 'visibility': visibility }
    var dataPass = common_axios.post(baseURL + 'medengage/update_visibility_care', params)
    return dataPass;
};

export const updateCapacityCare = (capacity, careId, notes) => {
    const params = { 'careId': careId, 'capacity': capacity, 'notes': notes }
    var dataPass = common_axios.post(baseURL + 'medengage/update_capacity_care', params)
    return dataPass;
};

export const transferMembers = (selectedData, reason, reasonText, type, startDate, endDate, oldCareId, dateStartNew) => {
    const params = { 'selectedData': selectedData, 'reason': reason, 'reasonText': reasonText, 'type': type, 'startDate': startDate, 'endDate': endDate, 'oldCareId': oldCareId, 'dateStartNew': dateStartNew }
    var dataPass = common_axios.post(baseURL + 'medengage/transfer_members', params)
    return dataPass;
};

export const transferBulkMembers = (transferFromCNID, transferReason, transferReasonOthers, transferType, transferToCN, memberCountToTransfer, startDate, endDate, dateStart) => {
    const params = { 'transferFromCNID': transferFromCNID, 'transferReason': transferReason, 'transferReasonOthers': transferReasonOthers, 'transferType': transferType, 'transferToCN': transferToCN, 'memberCountToTransfer': memberCountToTransfer, 'startDate': startDate, 'endDate': endDate, 'dateStartNew': dateStart }
    var dataPass = common_axios.post(baseURL + 'medengage/bulk_transfer_members', params)
    return dataPass;
};

export const fetchCarePerformanceData = (startDateNew, endDateNew, program, selectedValue) => {
    const params = { 'startRange': startDateNew, 'endRange': endDateNew, 'program': program, 'condition': selectedValue }
    // const params = encryptDataNew(paramsArray);
    var dataPass = common_axios.post(baseURL + 'medengage/care_performance_data', params)
    return dataPass;
};

export const fetchCareGraphData = (startDateNew, endDateNew, program, selectedValue) => {
    const params = { 'startRange': startDateNew, 'endRange': endDateNew, 'program': program, 'condition': selectedValue }
    var dataPass = common_axios.post(baseURL + 'medengage/care_user_graph', params)
    return dataPass;
};

export const fetchProgramPerformanceData = () => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'medengage/program_performance_data', params)
    return dataPass;
};


export const fetchProgramList = (programId) => {
    const params = { 'programId': programId }
    var dataPass = common_axios.post(baseURL + 'medengage/fetch_program_condition_list', params)
    return dataPass;
};

//fetch members per program
export const fetchProgramUsers = (startDateNew, endDateNew, programId, conditionId) => {
    const params = { 'startRange': startDateNew, 'endRange': endDateNew, 'program': programId, 'condition': conditionId }
    var dataPass = common_axios.post(baseURL + 'medengage/member_user_graph', params)
    return dataPass;
};

export const fetchProgramUsersTwo = (startDateNew, endDateNew, programId, conditionId) => {
    const params = { 'startRange': startDateNew, 'endRange': endDateNew, 'program': programId, 'condition': conditionId }
    var dataPass = common_axios.post(baseURL + 'medengage/member_user_graph_two', params)
    return dataPass;
};

export const fetchProgramUsersProgramCare = (startDateNew, endDateNew, programId, conditionId) => {
    const params = { 'startRange': startDateNew, 'endRange': endDateNew, 'program': programId, 'condition': conditionId }
    var dataPass = common_axios.post(baseURL + 'medengage/member_user_graph_program_care', params)
    return dataPass;
};

export const pathwayBreakupProgram = (conditionId) => {
    const params = { 'condition': conditionId }
    var dataPass = common_axios.post(baseURL + 'medengage/pathway_breakup_program', params)
    return dataPass;
};

export const fetch_ad_users_list = (searchString) => {
    var requestBody = { searchString };
    var dataPass = common_axios.post(baseURL + 'medengage/fetch_ad_users', requestBody);
    return dataPass;
};

export const fetch_program_condition = (searchString) => {
    var dataPass = common_axios.get(baseURL + 'medengage/all_program_condition');
    return dataPass;
};

export const fetch_supervisors_list = (searchString) => {
    var dataPass = common_axios.get(baseURL + 'medengage/fetch_supervisors_list');
    return dataPass;
};


export const createCareNavigator = (formData) => {
    var dataPass = common_axios.post(baseURL + 'medengage/add_care_navigator', formData)
    return dataPass;
};

export const fetchCareTranferData = (careId) => {
    const params = { 'careId': careId }
    var dataPass = common_axios.post(baseURL + 'medengage/fetch_care_tranfer_data', params)
    return dataPass;
}

export const fetchCareTranferDataV2 = (careId, searchStr, pageNumber, perPage, sortColumn, sortDirection) => {
    const params = { 'careId': careId, 'searchStr': searchStr, 'pageNumber': pageNumber, 'perPage': perPage, 'sortColumn': sortColumn, 'sortDirection': sortDirection }
    var dataPass = common_axios.post(baseURL + 'medengage/fetch_care_tranfer_data_v2', params)
    return dataPass;
}

export const cancelCareTransfer = (actionId, total) => {
    const params = { 'action': actionId, 'total': total }
    var dataPass = common_axios.post(baseURL + 'medengage/cancel_care_transfer', params)
    return dataPass;
}

export const allTransferUsers = (actionId) => {
    const params = { 'action': actionId }
    var dataPass = common_axios.post(baseURL + 'medengage/all_transfer_users', params)
    return dataPass;
}

export const fetchCareNavigator = (formData) => {
    var dataPass = common_axios.post(baseURL + 'medengage/fetch_care_navigator', formData)
    return dataPass;
};

export const updateCareNavigator = (formData) => {
    var dataPass = common_axios.post(baseURL + 'medengage/update_care_navigator', formData);
    return dataPass;
};

export const updateCareNavigatorMultiPart = (formData) => {
    var dataPass = common_axios.post(baseURL + 'medengage/update_care_navigator_multipart', formData);
    return dataPass;
};

export const removeCareNavigatorCertification = (formData) => {
    var dataPass = common_axios.post(baseURL + 'medengage/remove_care_navigator_cert', formData);
    return dataPass;
};

export const fetchMembersDisenroll = () => {
    var dataPass = common_axios.post(baseURL + 'medengage/fetch_all_members_disenroll', {});
    return dataPass;
};

export const updateMembersDisenroll = (formData) => {
    var dataPass = common_axios.post(baseURL + 'medengage/update_members_disenroll', formData)
    return dataPass;
};

// fetch_admin_user_data
export const fetch_admin_user_data = () => {
    var dataPass = common_axios.get(baseURL + 'medengage/fetch_admin_user_data');
    return dataPass;
};


//fetch role master data MTP
export const fetch_role_data = () => {
    var dataPass = common_axios.get(baseURL + 'medengage/fetch_role_data');
    return dataPass;
};


//fetch program master data MTP
export const fetch_program_data = () => {
    var dataPass = common_axios.get(baseURL + 'medengage/fetch_program_data');
    return dataPass;
};


//post data user creation MTP
export const medengage_user_creation_data = (formData) => {
    var dataPass = common_axios.post(baseURL + 'medengage/medengage_user_creation_data', formData);
    return dataPass;
};

//To get admin user information
export const medengage_fetch_admin_user_data = (userId) => {
    var dataPass = common_axios.get(baseURL + 'medengage/medengage_fetch_admin_user_data?userId=' + userId);
    return dataPass;
};


//Update data admin user MTP
export const medengage_admin_user_update_data = (userId, formData) => {
    var dataPass = common_axios.post(baseURL + 'medengage/medengage_admin_user_update_data?userId=' + userId, formData);
    return dataPass;
};

export const fetchCareCommunicationData = (startDate, endDate) => {
    let params = { 'startDate': startDate, 'endDate': endDate }
    var dataPass = common_axios.post(baseURL + 'medengage/fetch_care_communication_data', params);
    return dataPass;
};

export const fetchAudioPlayListCare = (callId) => {
    let params = { 'callId': callId }
    let response = common_axios.post('medengage/fetch_audio_play_list_care', params);
    return response;
}

export const fetch_reports_care_coordinator = () => {
    var dataPass = common_axios.get(baseURL + 'medengage/fetch_reports_care_coordinator');
    return dataPass;
};

export const fetch_reports_care_coordinator_v2 = (pageNumber, perPage, sortColumn, sortDirection) => {
    const params = { 'pageNumber': pageNumber, 'perPage': perPage, 'sortColumn': sortColumn, 'sortDirection': sortDirection }
    var dataPass = common_axios.post(baseURL + 'medengage/fetch_reports_care_coordinator_v2', params);
    return dataPass;
};

export const fetch_download_care_coordinator = (startDate, endDate, downloadEvent) => {
    let params = { 'startDate': startDate, 'endDate': endDate, 'downloadEvent': downloadEvent }
    let response = common_axios.post('medengage/fetch_download_care_coordinator', params);
    return response;
}

export const getFileLink = (id) => {
    let params = { 'reportId': id }
    var dataPass = common_axios.post(baseURL + 'medengage/fetch_fileurl', params);
    return dataPass;
};

export const fetchCNCalendarCalls = (cnId, cancelToken) => {
    const params = { 'cnId': cnId }
    var dataPass = common_axios.post(baseURL + 'medengage/fetch_cn_calendar_calls', params, cancelToken)
    return dataPass;
};

export const fetchCNEmptySlots = (cnId, date, cancelToken) => {
    const params = { 'cnId': cnId, 'date': date }
    var dataPass = common_axios.post(baseURL + 'medengage/fetch_empty_calendar_slots', params, cancelToken)
    return dataPass;
};

export const fetchConditions = (cancelToken) => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'medengage/fetch_conditions', params, cancelToken)
    return dataPass;
}
export const updateWebexSetting = (adminId, webexEnable) => {
    const params = { 'adminId': adminId, 'webexEnable': webexEnable }
    var dataPass = common_axios.post(baseURL + 'medengage/update_webex_setting', params)
    // return true;
    return dataPass;
};

export const reEnrollMember = (userId, cnId, programId, conditionId, pathwayId, callDate, callTime, pathwayMonth, remarks, membersDisenrollLogId, cancelToken) => {
    const params = { 'userId': userId, 'cnId': cnId, 'programId': programId, 'conditionId': conditionId, 'pathwayId': pathwayId, 'pathwayMonth': pathwayMonth, 'callDate': callDate, 'callTime': callTime, 'remarks': remarks, 'membersDisenrollLogId': membersDisenrollLogId }
    var dataPass = common_axios.post(baseURL + 'medengage/re_enroll_member', params, cancelToken)
    return dataPass;
};

export const fetchProgramConditionPathway = (userId, cancelToken) => {
    const params = { 'userId': userId }
    var dataPass = common_axios.post(baseURL + 'medengage/fetch_program_condition_pathway', params, cancelToken)
    return dataPass;
};

export const fetchAvailableAppointmentSlots = (queryDate, cnId) => {
    const params = { 'queryDate': queryDate, 'cnId': cnId }
    var dataPass = common_axios.post(baseURL + 'medengage/fetch_available_appointment_slot', params);
    return dataPass;
};

export const fetchMembersDisenrollNew = (searchStr, programId, conditionId, pageNumber, perPage, sortColumn, sortDirection, download) => {
    const params = { 'searchStr': searchStr, 'programId': programId, 'conditionId': conditionId, 'pageNumber': pageNumber, 'perPage': perPage, 'sortColumn': sortColumn, 'sortDirection': sortDirection, 'download': download }
    var dataPass = common_axios.post(baseURL + 'medengage/fetch_members_disenroll_new', params);
    return dataPass;
};

export const fetchMembersDisenrollPast = (searchStr, programId, conditionId, pageNumber, perPage, sortColumn, sortDirection, download) => {
    const params = { 'searchStr': searchStr, 'programId': programId, 'conditionId': conditionId, 'pageNumber': pageNumber, 'perPage': perPage, 'sortColumn': sortColumn, 'sortDirection': sortDirection, 'download': download }
    var dataPass = common_axios.post(baseURL + 'medengage/fetch_members_disenroll_past', params);
    return dataPass;
};

export const fetchMembersReenrolled = (searchStr, programId, conditionId, pageNumber, perPage, sortColumn, sortDirection, download) => {
    const params = { 'searchStr': searchStr, 'programId': programId, 'conditionId': conditionId, 'pageNumber': pageNumber, 'perPage': perPage, 'sortColumn': sortColumn, 'sortDirection': sortDirection, 'download': download }
    var dataPass = common_axios.post(baseURL + 'medengage/fetch_members_reenrolled', params);
    return dataPass;
};

export const fetchMember360Profile = (membershipNumber) => {
    const params = { 'membershipNumber': membershipNumber }
    var dataPass = common_axios.post(baseURL + 'observations/fetch_member_360profile', params);
    return dataPass;
};

export const carenavigatorAppointmentsOverview = (startDateNew, endDateNew, cn, supervisor) => {
    const params = { 'startRange': startDateNew, 'endRange': endDateNew, 'cn': cn, 'supervisor': supervisor }
    var dataPass = common_axios.post(baseURL + 'medengage/carenavigator_appointments_overview', params)
    return dataPass;
};

export const carenavigatorAppointmentsOverviewGraphs = (startDateNew, endDateNew, cn, supervisor) => {
    const params = { 'startRange': startDateNew, 'endRange': endDateNew, 'cn': cn, 'supervisor': supervisor }
    var dataPass = common_axios.post(baseURL + 'medengage/carenavigator_appointments_overview_graphs', params)
    return dataPass;
};

export const carenavigatorAppointmentsOverviewListDetails = (searchStr, startDateNew, endDateNew, cn, supervisor, pageNumber, perPage, sortColumn, sortDirection, download) => {
    const params = { 'searchStr': searchStr, 'startRange': startDateNew, 'endRange': endDateNew, 'cn': cn, 'supervisor': supervisor, 'pageNumber': pageNumber, 'perPage': perPage, 'sortColumn': sortColumn, 'sortDirection': sortDirection, 'download': download }
    var dataPass = common_axios.post(baseURL + 'medengage/carenavigator_appointments_overview_list_details', params)
    return dataPass;
};

export const fetch_cn_call_disposition_list = (searchStr, pageNumber, perPage, sortColumn, sortDirection) => {
    const params = { 'searchStr': searchStr, 'pageNumber': pageNumber, 'perPage': perPage, 'sortColumn': sortColumn, 'sortDirection': sortDirection }
    return common_axios.post(baseURL + 'medengage/fetch_cn_call_disposition_list', params);
}


export const add_cn_call_disposition = (formData) => {
    var dataPass = common_axios.post(baseURL + 'medengage/add_cn_call_disposition', formData);
    return dataPass;
};

export const fetch_cn_call_disposition_info = (id) => {
    let params = { 'dispositionId': id };
    var dataPass = common_axios.post(baseURL + 'medengage/fetch_cn_call_disposition_info', params);
    return dataPass;
};


export const edit_cn_call_disposition = (formData) => {
    console.log("form", formData);
    var dataPass = common_axios.post(baseURL + 'medengage/edit_cn_call_disposition', formData);
    return dataPass;
};

export const remove_cn_call_disposition = (id) => {
    let params = { 'dispositionId': id };
    var dataPass = common_axios.post(baseURL + 'medengage/remove_cn_call_disposition', params);
    return dataPass;
};

export const carenavigatorWhatsAppOverview = (startDateNew, endDateNew, cn, supervisor) => {
    let params = { 'startRange': startDateNew, 'endRange': endDateNew, 'cn': cn, 'supervisor': supervisor }
    var dataPass = common_axios.post(baseURL + 'medengage/carenavigator_whatsapp_overview', params)
    return dataPass;
};

export const carenavigatorWhatsappOverviewList = (startDateNew, endDateNew, cn, supervisor) => {
    let params = { 'startRange': startDateNew, 'endRange': endDateNew, 'cn': cn, 'supervisor': supervisor }
    var dataPass = common_axios.post(baseURL + 'medengage/carenavigator_whatsapp_overview_list', params)
    return dataPass;
};


export const fetchReportTypes = (cancelToken) => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'observations/fetch_report_types', params, cancelToken)
    return dataPass;
};

export const fetchHealthlockerFiles = (userId, reportTypeId, callId, cancelToken) => {
    const params = { 'userId': userId, 'reportTypeId': reportTypeId, 'callId': callId }
    var dataPass = common_axios.post(baseURL + 'observations/fetch_healthlocker_files_v2', params, cancelToken)
    return dataPass;
};

export const fetchHealthlockerFileUrl = (fileId, cancelToken) => {
    const params = { 'fileId': fileId }
    var dataPass = common_axios.post(baseURL + 'observations/fetch_healthlocker_file_url', params, cancelToken)
    return dataPass;
};

export const fetchHealthLockerFileInfo = (userId, fileId, cancelToken) => {
    const params = { 'userId': userId, 'fileId': fileId }
    var dataPass = common_axios.post(baseURL + 'observations/fetch_healthlocker_file_info', params, cancelToken)
    return dataPass;
};

export const removeHealthLockerFiles = (fileId, cancelToken) => {
    const params = { 'fileId': fileId }
    var dataPass = common_axios.post(baseURL + 'observations/remove_healthlocker_files', params, cancelToken)
    return dataPass;
};

export const addHealthLockerFile = (userId, formData, cancelToken) => {
    const params = { 'userId': userId, 'formData': formData }
    var dataPass = common_axios.post(baseURL + 'observations/add_healthlocker_file', params, cancelToken)
    return dataPass;
};

export const updateHealthlockerFile = (userId, formData, cancelToken) => {
    //const params = { 'userId': userId, 'fileId': formData['fileId'], 'file': formData['file'], 'originalName': formData['originalName'], 'reportTypeId': formData['reportTypeId'], 'title': formData['title'] }
    const params = { 'userId': userId, 'formData': formData }
    var dataPass = common_axios.post(baseURL + 'observations/update_healthlocker_file', params, cancelToken)
    return dataPass;
};

export const fetch_contract_priority_list = (searchStr, pageNumber, perPage, sortColumn, sortDirection) => {
    const params = { 'searchStr': searchStr, 'pageNumber': pageNumber, 'perPage': perPage, 'sortColumn': sortColumn, 'sortDirection': sortDirection }
    return common_axios.post(baseURL + 'medengage/fetch_contract_priority_list', params);
}


export const add_contract_priority = (formData) => {
    var dataPass = common_axios.post(baseURL + 'medengage/add_contract_priority', formData);
    return dataPass;
};

export const fetch_contract_priority_info = (id) => {
    let params = { 'recordId': id };
    var dataPass = common_axios.post(baseURL + 'medengage/fetch_contract_priority_info', params);
    return dataPass;
};


export const edit_contract_priority = (formData) => {
    var dataPass = common_axios.post(baseURL + 'medengage/edit_contract_priority', formData);
    return dataPass;
};

export const remove_contract_priority = (id) => {
    let params = { 'recordId': id };
    var dataPass = common_axios.post(baseURL + 'medengage/remove_contract_priority', params);
    return dataPass;
};

export const fetch_contract_details = (contractNumber) => {
    let params = { 'contractNumber': contractNumber };
    var dataPass = common_axios.post(baseURL + 'medengage/fetch_contract_details', params);
    return dataPass;
};

export const fetchCallDetails = (callId, cancelToken) => {
    const params = { 'callId': callId }
    var dataPass = common_axios.post(baseURL + 'medengage/fetch_call_details', params, cancelToken)
    return dataPass;
};
