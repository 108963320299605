import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { FaWhatsapp, FaArrowLeft, FaSync, FaInfoCircle, FaCopy, FaPlusCircle, FaUser, FaTag, FaReply, FaClock } from 'react-icons/fa';
import TableModal from './TableModal';
import { fetchCampgainDetail,fetchCampgainDetailStatistics } from '../../services/mtp/Service';
import { whatsappCampaignCompletedDataDownload } from '../../services/whatsapp/Service';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { LoaderLoader } from '../utils/CommonLibrary';
const CampaignDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPreview, setShowPreview] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [tableModal, setTableModal] = useState(false)
  const { campaignId } = useParams();
  const [campaignDetail, setCampaignDetail] = useState([]);
  const [campaignDetailTags, setCampaignDetailTags] = useState([]);
  const [statisticsData, setStatisticsData] = useState([]);
  const [statusTitle, setStatusTitle] = useState('View');
  const [popStatus, setPopStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  //pagination
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [currentRecordsRange, setCurrentRecordsRange] = useState('');
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  useEffect(() => {
    const pages = Math.ceil(totalRows / perPage);
    setTotalPages(pages);
}, [perPage,page,totalRows]);
   

useEffect(() => {
    const start = (page - 1) * perPage + 1;
    const end = Math.min(page * perPage, totalRows);
    if (start > 0 && end > 0) {
        setCurrentRecordsRange(`${start}-${end}`);
    }
    if(popStatus){
      handleCampgainDetailStatistics(popStatus);
    }
}, [page, perPage]);


  const fetchNotificationDetails=async()=>{
    try {
          setIsLoading(true); // Show loader at the start of the fetch
          let response = await fetchCampgainDetail(campaignId);

          if (response.data.code === 200) {
            setCampaignDetail(response.data.data);
            setCampaignDetailTags(response.data.data.filterDataNew);            
          } else {
            console.log("No record found");
          }
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false); // Ensure loader hides in both success and error cases
        }
  }

  const handleCampgainDetailStatistics=async(status)=>{
    try {
      setShowTableModal(true)
      setIsLoading(true); // Show loader at the start of the fetch
      let response = await fetchCampgainDetailStatistics(campaignId,status,page,perPage);
      setPopStatus(status);
      if(response.data.code == 200){
        setStatisticsData(response.data.data);
        setStatusTitle(status.charAt(0).toUpperCase() + status.slice(1));
        setTotalRows(response.data.totalRows);
      }else{
        console.log("No record found");
        setStatisticsData([]);
        setStatusTitle(status.charAt(0).toUpperCase() + status.slice(1));
      }
    }catch (error) {
      console.log(error); 
    } finally {
      setIsLoading(false); // Ensure loader hides in both success and error cases
    }
  }

  useEffect(() => {
    fetchNotificationDetails();
  }, []);

  //const campaignData = campaignDetail;
    const [showModal, setShowModal] = useState(false);

    const handleDownloadComplete =async()=>{
      try {
   
        let response = await whatsappCampaignCompletedDataDownload(campaignId);
        // const blob = await response.blob();
      
        // // Create a temporary link element for downloading
        // const url = window.URL.createObjectURL(blob);
        // const link = document.createElement("a");
        // link.href = url;
        // link.download = "data.csv";
        // document.body.appendChild(link);
        // link.click();
        
        // // Clean up after download
        // document.body.removeChild(link);
        // window.URL.revokeObjectURL(url);


        const blob = new Blob([response.data], { type: 'text/csv' });
            
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download','data.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        
            window.URL.revokeObjectURL(downloadUrl);
        if(response.data.code == 200){
          console.log("details",response.data.data);

        
        }else{
          console.log("No record found");
          setStatisticsData([]);
        }
      }catch (error) {
        console.log(error); 
      }
    }

    useEffect(() => {
        setMounted(true);
      }, []);
  
    const refreshData = () => {
      console.log("Refreshing data...");
      fetchNotificationDetails();
    };
  
    
    const [showTableModal, setShowTableModal] = useState(false);

    // Sample data - replace this with your actual data
    const sampleData = [
      { memberName: "John Doe", mobileNumber: "1234567890", time: "2024-10-29 10:30 AM" },
      { memberName: "Jane Smith", mobileNumber: "9876543210", time: "2024-10-29 11:45 AM" },
      // Add more sample data as needed
    ];
   

  const StatBox = ({ title, value, hasViewList = true, statisticsData,handleCampgainDetailStatistics,statusTitle,page,setPage,perPage,setPerPage,totalRows,setTotalRows,currentRecordsRange,setCurrentRecordsRange,totalPages,setTotalPages }) => {

    return (
      
      <div className="tw-bg-white tw-font-ibm-plex tw-rounded-lg tw-shadow-md tw-p-4 tw-text-center tw-w-1/5 tw-flex tw-flex-col tw-justify-between  tw-relative tw-transition-all tw-duration-300 hover:tw-shadow-lg">
        <div>
          <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
            <span className="tw-text-xl tw-text-center tw-font-bold tw-w-full tw-text-gray-700">{value}</span>
            <div className="tw-group tw-absolute tw-top-2 tw-right-2">
            </div>
          </div>
          <div className="tw-text-sm tw-text-gray-600">{title} <OverlayTrigger placement="top" overlay={<Tooltip>
                {title === 'Attempted' && <span>Total messages attempted to members.</span>}
                {title === 'Sent' && <span>Messages that were successfully sent to members.</span>}
                {title === 'Delivered' && <span>Messages that reached the member’s device.</span>}
                {title === 'Read' && <span>Messages that were opened and viewed by members.</span>}
                {title === 'Failed' && <span>Messages that could not be sent due to delivery issues.</span>}
              </Tooltip>}>
              <i class="bi bi-info-circle tw-text-xs"></i>
              </OverlayTrigger></div>
        </div>
        {hasViewList && (
          <>
            <span onClick={handleCampgainDetailStatistics}
              className="tw-text-gray-500 tw-text-sm tw-mt-2 hover:tw-text-gray-700 tw-transition-colors tw-duration-300 tw-cursor-pointer"
            >
              <i class="bi bi-eye" ></i>
            </span>
            <TableModal
              show={showTableModal}
              onClose={() => setShowTableModal(false)}
              data={statisticsData}
              title={`${statusTitle}`}
              page={page}
              setPage ={setPage}
              perPage={perPage}
              setPerPage={setPerPage}
              totalRows={totalRows}
              setTotalRows={setTotalRows}
              currentRecordsRange={currentRecordsRange}
              setCurrentRecordsRange = {setCurrentRecordsRange}
              totalPages={totalPages}
              setTotalPages={setTotalPages}


            />
          </>
        )}
      </div>
    );
  }

  const Modal = ({ show, onClose, children }) => {
    if (!show) return null;
    return (
      <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-justify-center tw-items-center tw-z-50 tw-transition-opacity tw-duration-300" style={{ opacity: show ? 1 : 0 }}>
        <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-max-w-2xl tw-w-full tw-max-h-[80vh] tw-overflow-y-auto tw-shadow-2xl tw-transform tw-transition-all tw-duration-300 tw-scale-95" style={{ transform: show ? 'scale(1)' : 'scale(0.95)' }}>
          {children}
          <button 
            onClick={onClose}
            className="btn btn-success uplaod-file-btn"
          >
            Okay, Got it!
          </button>
        </div>
      </div>
    );
  };

  console.log("campaignDetail",campaignDetail);
  

  return (
    <div
      className={`tw-container tw-mx-auto tw-p-4 tw-transition-opacity tw-duration-500 ${
        mounted ? "tw-opacity-100" : "tw-opacity-0"
      }`}
    >
      <LoaderLoader isLoading={isLoading} />
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-3">
        <div className="tw-flex tw-items-center tw-space-x-4">
          <button
            className="btn btn-primary back-btn mb-0"
            onClick={() => navigate("/notification-dashboard")}
          >
            <i className="bi bi-chevron-left me-1"></i> Back
          </button>
          <h1 className="tw-text-2xl tw-font-bold tw-text-gray-600 tw-mt-1.5">
            {campaignDetail.campaignName}
          </h1>
          <FaWhatsapp className="tw-text-green-500" />
          <span className="tw-bg-sky-100 tw-text-gray-800 tw-capitalize tw-px-3 tw-py-1 tw-rounded-full tw-text-sm tw-font-semibold">
            {campaignDetail.scheduleStatus}
          </span>
          {/* <div className="tw-group tw-relative">
            <FaCopy 
              className="tw-text-gray-400 tw-cursor-pointer tw-transition-transform tw-duration-300 hover:tw-scale-110" 
              onClick={copyToClipboard} 
            />
            <div className="tw-hidden group-hover:tw-block tw-absolute tw-z-50 tw-bg-sky-800 tw-text-white tw-p-2 tw-rounded tw-text-sm tw-mt-1 tw-left-1/2 tw-transform tw--translate-x-1/2">
              Copy campaign
            </div>
          </div> */}
        </div>
      </div>

      <div className="tw-bg-white tw-p-4 tw-rounded-lg tw-flex tw-items-center tw-space-x-2 tw-mb-6 tw-shadow-md">
        <div className="tw-group tw-relative">
          {/* <FaInfoCircle className="tw-text-gray-500" /> */}
          {/* <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top">Refresh data info</Tooltip>}>
          <i class="bi bi-info-circle tw-text-xs"></i>
          </OverlayTrigger> */}
          {/* <div className="tw-hidden group-hover:tw-block tw-absolute tw-z-50 tw-bg-sky-800 tw-text-white tw-p-2 tw-rounded tw-text-sm tw-mt-1 tw-left-1/2 tw-transform tw--translate-x-1/2">
            Refresh data info
          </div> */}
        </div>
        <span className="tw-flex-grow tw-text-gray-700">
          Click 'Refresh Data' to see the most current stats on this page
        </span>
        <button
          onClick={refreshData}
          className="btn btn-success uplaod-file-btn"
        >
          Refresh Data
        </button>
      </div>

      <div className="tw-mb-8">
        <div className="tw-flex tw-justify-between tw-mb-4">
          <div className="tw-flex tw-flex-row gap-2 tw-items-center">
            <h2 className="tw-text-2xl tw-font-semibold tw-text-gray-500">
              Statistics{" "}
            </h2>
            {/* <span 
              className="tw-bg-white border tw-border-black tw-px-1 tw-py-0.5 tw-text-xs hover:tw-bg-black hover:tw-text-white tw-rounded-lg tw-cursor-pointer tw-transition-all tw-duration-300"
              onClick={() =>setShowModal(true)}
            >
             <i className="bi bi-info-circle tw-mr-1"></i> info
            </span> */}
            <span
              onClick={() => setShowModal(true)}
              className="tw-text-gray-500 "
            >
              Click{" "}
              <span className="tw-text-sky-700 tw-cursor-pointer">here</span> to
              know your Data.
            </span>
          </div>
          <div className="tw-space-x-4">
            {campaignDetail.scheduleStatus === "completed" ? (
              <button
                className="btn btn-success uplaod-file-btn"
                onClick={handleDownloadComplete}
              >
                <i class="bi bi-download tw-mr-2"></i> Export
              </button>
            ) : null}
          </div>
        </div>
        <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-4 tw-w-full">
          <StatBox
            title="Attempted"
            value={`${
              campaignDetail?.totalUserAttempted !== undefined
                ? campaignDetail?.totalUserAttempted
                : "0"
            } out of ${
              campaignDetail?.totalUserAttempted !== undefined
                ? campaignDetail?.totalUserAttempted
                : "0"
            }`}
            statisticsData={statisticsData}
            handleCampgainDetailStatistics={() =>
              handleCampgainDetailStatistics("attempted")
            }
            statusTitle={statusTitle}
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
            totalRows={totalRows}
            setTotalRows={setTotalRows}
            currentRecordsRange={currentRecordsRange}
            setCurrentRecordsRange={setCurrentRecordsRange}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
          />
          <StatBox
            title="Sent"
            value={campaignDetail.totalUserSent}
            statisticsData={statisticsData}
            handleCampgainDetailStatistics={() =>
              handleCampgainDetailStatistics("sent")
            }
            statusTitle={statusTitle}
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
            totalRows={totalRows}
            setTotalRows={setTotalRows}
            currentRecordsRange={currentRecordsRange}
            setCurrentRecordsRange={setCurrentRecordsRange}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
          />
          <StatBox
            title="Delivered"
            value={campaignDetail.totalUserDelivered}
            statisticsData={statisticsData}
            handleCampgainDetailStatistics={() =>
              handleCampgainDetailStatistics("delivered")
            }
            statusTitle={statusTitle}
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
            totalRows={totalRows}
            setTotalRows={setTotalRows}
            currentRecordsRange={currentRecordsRange}
            setCurrentRecordsRange={setCurrentRecordsRange}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
          />
          <StatBox
            title="Read"
            value={campaignDetail.totalUserRead}
            statisticsData={statisticsData}
            handleCampgainDetailStatistics={() =>
              handleCampgainDetailStatistics("read")
            }
            statusTitle={statusTitle}
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
            totalRows={totalRows}
            setTotalRows={setTotalRows}
            currentRecordsRange={currentRecordsRange}
            setCurrentRecordsRange={setCurrentRecordsRange}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
          />
          {/* <StatBox title="Replied" value={campaignDetail.totalUserRead}  hasViewList={false}  statisticsData={statisticsData} handleCampgainDetailStatistics={()=>handleCampgainDetailStatistics('replied')}/> */}
          <StatBox
            title="Failed"
            value={campaignDetail.totalUserUndelivered}
            statisticsData={statisticsData}
            handleCampgainDetailStatistics={() =>
              handleCampgainDetailStatistics("failed")
            }
            statusTitle={statusTitle}
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
            totalRows={totalRows}
            setTotalRows={setTotalRows}
            currentRecordsRange={currentRecordsRange}
            setCurrentRecordsRange={setCurrentRecordsRange}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
          />
        </div>
      </div>

      {/* <div className="tw-mb-8">
        <h2 className="tw-text-2xl tw-font-semibold tw-mb-4 tw-text-gray-500">Button Tracking</h2>
        <div className="tw-overflow-x-auto tw-shadow-md tw-rounded-lg">
          <table className="tw-w-full tw-border-collapse tw-border tw-border-gray-300">
            <thead>
              <tr className="tw-bg-gray-100">
                <th className="tw-border tw-border-gray-300 tw-p-3 tw-text-left tw-text-gray-500">Button Name</th>
                <th className="tw-border tw-border-gray-300 tw-p-3 tw-text-left tw-text-gray-500">Button Type</th>
                <th className="tw-border tw-border-gray-300 tw-p-3 tw-text-left tw-text-gray-500">Number of Clicks</th>
                <th className="tw-border tw-border-gray-300 tw-p-3 tw-text-left tw-text-gray-500">Click Percentage</th>
                <th className="tw-border tw-border-gray-300 tw-p-3 tw-text-left tw-text-gray-500">Users</th>
              </tr>
            </thead>
            <tbody>
              <tr className="tw-bg-white">
                <td colSpan="5" className="tw-border tw-border-gray-300 tw-p-4 tw-text-center tw-text-gray-500">
                  <FaPlusCircle className="tw-text-4xl tw-text-gray-300 tw-mx-auto tw-mb-2" />
                  No result found
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}

      <div className="tw-mb-16">
        <h2 className="tw-text-2xl tw-font-bold tw-mb-4 tw-text-gray-500">
          Detail
        </h2>

        <div className="tw-bg-white tw-rounded-lg tw-overflow-hidden tw-shadow-md">
          <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-divide-y md:tw-divide-y-0 md:tw-divide-x tw-divide-sky-200">
            {/* <div className="tw-p-4 tw-bg-white">
              <h3 className="tw-text-gray-700 tw-font-semibold tw-mb-2">Notification type</h3>
              <p className="tw-text-gray-600">{campaignDetail.notificationType}</p>
            </div> */}
            <div className="tw-p-4">
              <h5 className="tw-text-gray-700 tw-font-semibold tw-mb-2">
                Members
              </h5>
              <p className="tw-flex tw-items-center tw-text-gray-600">
                <FaUser className="tw-mr-2 tw-text-gray-600" />
                {campaignDetail.totalAudienceUserCount}
              </p>
              {campaignDetailTags?.map((value, index) => {
                // Function to format value with line breaks after 5 commas
                const formatValue = (value) => {
                  if (!value) return "";
                  const values = value.split(",");
                  const chunks = [];

                  for (let i = 0; i < values.length; i += 5) {
                    chunks.push(values.slice(i, i + 5).join(","));
                  }

                  return chunks.join(",\n");
                };

                return (
                  <div key={index} className="tw-mb-2">
                    {value.field === "policyEndDate" ||
                    value.field === "enrollmentTime" ? (
                      <div className="tw-flex tw-items-start tw-gap-2 tw-text-gray-600 tw-min-w-0">
                        <FaTag className="tw-mt-1 tw-flex-shrink-0" />
                        <p className="tw-whitespace-pre-wrap tw-flex-1">
                          {value.field} {value.operator} <br />{" "}
                          {formatValue(value.value)}
                        </p>
                      </div>
                    ) : null}

                    {value.field === "conditionId" ? (
                      <div className="tw-flex tw-items-start tw-gap-2 tw-text-gray-600 tw-min-w-0">
                        <FaTag className="tw-mt-1 tw-flex-shrink-0" />
                        <p className="tw-whitespace-pre-wrap tw-flex-1">
                          Condition between <br /> {formatValue(value.value)}
                        </p>
                      </div>
                    ) : null}

                    {value.field === "enrollmentStatus1" ? (
                      <div className="tw-flex tw-items-start tw-gap-2 tw-text-gray-600 tw-min-w-0">
                        <FaTag className="tw-mt-1 tw-flex-shrink-0" />
                        <p className="tw-whitespace-pre-wrap tw-flex-1">
                          enrollmentStatus between <br />{" "}
                          {formatValue(value.value)}
                        </p>
                      </div>
                    ) : null}

                    {value.field === "contractNo" ||
                    value.field === "contractName" ? (
                      <div className="tw-flex tw-items-start tw-gap-2 tw-text-gray-600 tw-min-w-0">
                        <FaTag className="tw-mt-1 tw-flex-shrink-0" />
                        <p className="tw-whitespace-pre-wrap tw-flex-1">
                          {value.field} {value.operator} <br />{" "}
                          {formatValue(value.value)}
                        </p>
                      </div>
                    ) : null}

                    {value.field === "campaignSentDate" ? (
                      <div className="tw-flex tw-items-start tw-gap-2 tw-text-gray-600 tw-min-w-0">
                        <FaTag className="tw-mt-1 tw-flex-shrink-0" />
                        <p className="tw-whitespace-pre-wrap tw-flex-1">
                          WhatsApp Contacted On {value.operator} <br />{" "}
                          {formatValue(value.value)}
                        </p>
                      </div>
                    ) : null}

                    {value.field === "notCantactDate" ? (
                      <div className="tw-flex tw-items-start tw-gap-2 tw-text-gray-600 tw-min-w-0">
                        <FaTag className="tw-mt-1 tw-flex-shrink-0" />
                        <p className="tw-whitespace-pre-wrap tw-flex-1">
                          WhatsApp Not Contacted On {value.operator} <br />{" "}
                          {formatValue(value.value)}
                        </p>
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
            <div className="tw-p-4 tw-bg-white md:tw-col-span-2">
              {campaignDetail.headerContent && (
                <>
                  <h5 className="tw-text-gray-700 tw-font-semibold tw-mb-2">
                    Header
                  </h5>
                  <p className="tw-mb-4 tw-text-gray-600">
                    {campaignDetail.headerContent}
                  </p>
                </>
              )}
              <h5 className="tw-text-gray-700 tw-font-semibold tw-mb-2">
                Message
              </h5>
              <p className="tw-mb-2">
                <span className="tw-font-medium tw-text-gray-600">
                  Template Name:
                </span>{" "}
                {campaignDetail.templateName}
              </p>
              <div className="tw-bg-white tw-shadow-md tw-p-4 tw-rounded tw-mb-4">
                <p className="tw-whitespace-pre-wrap tw-text-gray-700">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: campaignDetail.templateBody,
                    }}
                  ></span>
                </p>
                {campaignDetail.mediaUrl &&
                  campaignDetail.headerType === "Image" && (
                    <img
                      src={campaignDetail.mediaUrl}
                      alt={campaignDetail.headerType}
                      className="tw-max-w-[500px] tw-object-contain"
                    />
                  )}

                {campaignDetail.mediaUrl &&
                  campaignDetail.headerType === "Document" && (
                    <a
                      href={campaignDetail.mediaUrl}
                      target="_blank"
                      alt={campaignDetail.headerType}
                    >
                      {" "}
                      View Document
                    </a>
                  )}

                {campaignDetail.mediaUrl &&
                  campaignDetail.headerType === "Video" && (
                    <a
                      href={campaignDetail.mediaUrl}
                      target="_blank"
                      alt={campaignDetail.headerType}
                    >
                      {" "}
                      View Video
                    </a>
                  )}
              </div>
              {campaignDetail.footerContent && (
                <>
                  <h5 className="tw-text-gray-700 tw-font-semibold tw-mb-2">
                    Footer
                  </h5>
                  <p className="tw-mb-4 tw-text-gray-600">
                    {campaignDetail.footerContent}
                  </p>
                </>
              )}
              {Array.isArray(campaignDetail.websiteUrl) && (
                <div className="tw-mb-4">
                  <h5 className="tw-font-bold tw-mb-2">Buttons:</h5>
                  <div className="tw-bg-white tw-p-3 tw-rounded tw-border tw-border-gray-200">
                    {campaignDetail.websiteUrl.map((button, index) => (
                      <div key={index} className="tw-mb-2 last:tw-mb-0">
                        <p>
                          <span className="tw-font-semibold">Text:</span>{" "}
                          {button.title}
                        </p>
                        {button.url && (
                          <p>
                            <span className="tw-font-semibold">URL:</span>{" "}
                            {button.url}
                          </p>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}

<div className="">
        <h5 className="tw-text-gray-700 tw-font-semibold tw-mb-2">
          Buttons:
        </h5>
        <div className="tw-bg-white tw-mb-4 tw-rounded tw-border tw-border-gray-200">
          {campaignDetail.buttons && campaignDetail.buttons.length > 0 ? (
            <div className="tw-space-y-2">
              {JSON.parse(campaignDetail.buttons).map((button, index) => (
                <p
                  key={index}
                  className="tw-text-sm tw-break-words"
                >
                  {button.type}:
                   {button.title},
                </p>
              ))}
            </div>
          ) : (
            <p className="tw-text-sm tw-break-words">
              No buttons
            </p>
          )}
        </div>
      </div>

      <div className="">
        <h5 className="tw-text-gray-700 tw-font-semibold tw-mb-2">
          Website URLs & Phone Numbers:
        </h5>
        <div className="tw-bg-white tw-mb-4 tw-rounded tw-border tw-border-gray-200">
        {campaignDetail.websiteUrl && campaignDetail.websiteUrl.length > 0 ? (
            <div className="tw-space-y-2">
              {JSON.parse(campaignDetail.websiteUrl).map((link, index) => (
                <p
                  key={index}
                  className="tw-text-sm tw-break-words"
                >
                  {link.type === "URL"
                    ? `URL: ${link.title} (${link.url})`
                    : link.type === "PHONE_NUMBER"
                    ? `Phone: ${link.title} (${link.phone})`
                    : ""}
                </p>
              )) 
              }
            </div>
          ) : (
            <p className="tw-text-sm tw-break-words">
              No website URLs or phone numbers
            </p>
          )}
        </div>
      </div>
                <button
                  onClick={() => setShowPreview(true)}
                  className="btn btn-success uplaod-file-btn"
                >
                  See Template Preview
                </button>
            </div>
            {/* <div className="tw-p-4">
              <h3 className="tw-text-gray-700 tw-font-semibold tw-mb-2">Reply Flow</h3>
              <p className="tw-flex tw-items-center tw-text-gray-600"><FaReply className="tw-mr-2 tw-text-gray-600" />No chat flows are set for this campaign</p>
            </div> */}
            {campaignDetail.scheduleStatus === "scheduled" ? (
              <>
                <div className="tw-p-4  tw-bg-white">
                  <h3 className="tw-text-gray-700 tw-font-semibold tw-mb-2">
                    Schedule
                  </h3>
                  <p className="tw-flex tw-items-center tw-mb-1 tw-text-gray-600">
                    <FaClock className="tw-mr-2 tw-text-gray-600" />
                    Started on: {campaignDetail.scheduleTimeDate}
                  </p>
                  {campaignDetail.scheduleEndTimeDate && (
                    <p className="tw-flex tw-items-center tw-text-gray-600">
                      <FaClock className="tw-mr-2 tw-text-gray-600" />
                      Ended on: {campaignDetail.scheduleEndTimeDate}
                    </p>
                  )}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>

      {showPreview && (
        <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-justify-center tw-items-center tw-p-4 tw-z-50">
          <div className="tw-bg-white tw-rounded-lg tw-p-4 tw-max-h-[80vh] tw-overflow-y-auto tw-shadow-2xl">
            <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
              <h3 className="tw-text-lg tw-font-bold tw-text-gray-800">
                Preview
              </h3>
              <button
                onClick={() => setShowPreview(false)}
                className="tw-text-gray-500 tw-text-3xl hover:tw-text-gray-700 tw-border-none tw-bg-transparent"
              >
                ×
              </button>
            </div>

            <div className="tw-w-full md:tw-w-[360px] tw-mx-auto">
              <div className="tw-relative tw-h-[600px]">
                <div className="tw-w-full tw-h-full tw-bg-[url('https://appcdn.goqii.com/storeimg/49871_1729598479.png')] tw-bg-contain tw-bg-no-repeat tw-bg-center"></div>

                <div className="tw-absolute tw-top-[85px] tw-left-[25px] tw-right-[25px] tw-bottom-[85px] tw-overflow-y-auto [&::-webkit-scrollbar]:tw-hidden [-ms-overflow-style:'none'] [scrollbar-width:'none']">
                  <div className="tw-text-xs tw-text-gray-700 tw-bg-white tw-rounded-lg tw-px-2 tw-w-1/3 tw-text-center tw-mx-auto tw-mb-1 tw-font-semibold">
                    Today
                  </div>

                  <div className="tw-bg-white tw-rounded-lg tw-p-3 tw-shadow-sm">
                    {/* Header Section */}
                    {campaignDetail.headerContent && (
                      <div className="tw-text-sm tw-text-gray-600 tw-mb-2">
                        {campaignDetail.headerContent}
                      </div>
                    )}

                    {/* Media Section */}
                    {campaignDetail.mediaUrl && (
                      <div className="tw-mb-3">
                        {campaignDetail.headerType === "Image" && (
                          <img
                            src={campaignDetail.mediaUrl}
                            alt="Media Content"
                            className="tw-rounded-lg tw-w-full tw-max-h-[200px] tw-object-cover"
                          />
                        )}
                        {campaignDetail.headerType === "Video" && (
                          <video
                            controls
                            className="tw-rounded-lg tw-w-full tw-max-h-[200px] tw-object-cover"
                          >
                            <source
                              src={campaignDetail.mediaUrl}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        )}
                        {campaignDetail.headerType === "Document" && (
                          <div className="tw-bg-gray-50 tw-p-3 tw-rounded-lg tw-flex tw-items-center tw-gap-2">
                            <i className="bi bi-file-earmark-pdf tw-text-red-500"></i>
                            <span className="tw-text-sm tw-text-gray-600">
                              Document
                            </span>
                          </div>
                        )}
                      </div>
                    )}

                    {/* Message Body */}
                    <div className="tw-text-gray-600 tw-text-sm tw-mb-3">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: campaignDetail.templateBody,
                        }}
                      ></span>
                    </div>

                    {/* Footer Content */}
                    {campaignDetail.footerContent && (
                      <div className="tw-text-xs tw-text-gray-500 tw-mb-3">
                        {campaignDetail.footerContent}
                      </div>
                    )}

                    {/* Website URL Buttons */}
                    {campaignDetail.websiteUrl &&
                      typeof campaignDetail.websiteUrl === "string" &&
                      campaignDetail.websiteUrl !== "" && (
                        <div className="tw-space-y-2">
                          {JSON.parse(campaignDetail.websiteUrl).map(
                            (button, index) => (
                              <div key={index} className="tw-w-full">
                                {button.type === "URL" && (
                                  <button className="tw-w-full tw-bg-[#e9edef] hover:tw-bg-gray-200 tw-text-[#0177b5] tw-py-2 tw-px-3 tw-rounded tw-text-sm tw-font-medium tw-flex tw-items-center tw-justify-center">
                                    <i className="bi bi-link-45deg tw-mr-2"></i>
                                    {button.title}
                                  </button>
                                )}
                                {button.type === "PHONE_NUMBER" && (
                                  <button className="tw-w-full tw-bg-[#e9edef] hover:tw-bg-gray-200 tw-text-[#0177b5] tw-py-2 tw-px-3 tw-rounded tw-text-sm tw-font-medium tw-flex tw-items-center tw-justify-center">
                                    <i className="bi bi-telephone tw-mr-2"></i>
                                    {button.title}
                                  </button>
                                )}
                              </div>
                            )
                          )}
                        </div>
                      )}

                    {/* Quick Reply Buttons */}
                    {campaignDetail.buttons &&
                      typeof campaignDetail.buttons === "string" &&
                      campaignDetail.buttons !== "" && (
                        <div className="tw-space-y-2 tw-mt-3">
                          {JSON.parse(campaignDetail.buttons).map(
                            (button, index) => (
                              <button
                                key={index}
                                className="tw-w-full tw-bg-white tw-border tw-border-gray-200 hover:tw-bg-gray-50 tw-text-gray-600 tw-py-2 tw-px-3 tw-rounded-full tw-text-sm tw-font-medium tw-text-center"
                              >
                                {button.title}
                              </button>
                            )
                          )}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <h2 className="tw-text-xl tw-font-bold tw-mb-4 tw-text-gray-800">
          Know Your Data
        </h2>
        <ul className="tw-list-disc tw-pl-5 tw-space-y-2 tw-text-gray-700">
          <li>
            Sent, Delivered, Read, Replied, and Failed statuses will update as
            we receive information from Meta.
          </li>
          <li>
            Delivered, Read, and Replied statuses may update indefinitely due to
            external factors like network strength and recipient actions.
          </li>
          <li>
            Sent and Failed counts should equal the Attempted count, but slight
            data discrepancies may cause variations.
          </li>
          <li>
            Attempted denotes the count of notifications HealthEngage tried to
            send to WhatsApp.
          </li>
          <li>
            Sent denotes the count of notifications successfully sent to the
            recipient's phone, but it doesn't guarantee receipt, as the phone
            could be off or in a poor network zone (equivalent to a single tick
            on WhatsApp).
          </li>
          <li>
            Failed denotes the count of notifications that couldn't be sent to
            the recipient's phone.
          </li>
          <li>
            Delivered denotes the count of notifications successfully received
            by the recipient (equivalent to a double tick on WhatsApp).
          </li>
          <li>
            Read denotes the count of notifications read by the recipient
            (equivalent to a blue tick on WhatsApp).
          </li>
          <li>
            Replied denotes the count of notifications which received a reply
            within 72 hours.
          </li>
        </ul>
      </Modal>
    </div>
  );
};

export default CampaignDetails;