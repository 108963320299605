import React, { useState, useEffect } from "react";
import BackendDrivenDataTable from "../../cn/components/common/BackendDrivenDataTable.js";

const DEFAULT_STATUS = "All";
const DEFAULT_CATEGORY = "All";
const DEFAULT_LANGUAGE = "All";
const DEFAULT_SEARCH_QUERY = "";

const StatusBadge = ({ status }) => {
    // Convert status to lowercase once for comparison
    const statusLower = status.toLowerCase();

    console.log(statusLower);
    
    return (
      <span
        style={
          statusLower === "approved"
            ? { color: "#4CAF50", fontSize: "12px", fontWeight: "500", textTransform: "capitalize" }
            : statusLower === "approval pending"
            ? { color: "#FFC107", fontSize: "12px", fontWeight: "500", textTransform: "capitalize" }
            : { color: "#FF3131", fontSize: "12px", fontWeight: "500", textTransform: "capitalize" }
        }
      >
        {status}
      </span>
    );
  };

const CareCoordinateDeletedTemplates = ({ templates }) => {
  const [searchQuery, setSearchQuery] = useState(DEFAULT_SEARCH_QUERY);
  const [selectedCategory, setSelectedCategory] = useState(DEFAULT_CATEGORY);
  const [selectedStatus, setSelectedStatus] = useState(DEFAULT_STATUS);
  const [selectedLanguage, setSelectedLanguage] = useState(DEFAULT_LANGUAGE);

  // New state for BackendDrivenDataTable
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [displayData, setDisplayData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const columns = [
    {
      name: "Template Name",
      selector: row => row.templateName,
      sortable: true,
    },
    {
      name: "Status",
      selector: row => <StatusBadge status={row.status} />,
      sortable: true,
    },
    {
      name: "Category",
      selector: row => row.category,
      sortable: true
    },
    {
      name: "Language",
      selector: row => row.language,
      sortable: true
    },
    {
      name: "Created By",
      selector: row => row.createdBy,
      sortable: true
    },
  ];

  // Filter and update data when filters or pagination changes
  useEffect(() => {
    // Apply filters
    const filteredData = templates.templateData?.filter((item) => {
      const matchesSearchQuery = item.templateName
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      const matchesCategory =
        selectedCategory === DEFAULT_CATEGORY ||
        item.category === selectedCategory;
      const matchesStatus =
        selectedStatus === DEFAULT_STATUS || item.status === selectedStatus;
      const matchesLanguage =
        selectedLanguage === DEFAULT_LANGUAGE ||
        item.language === selectedLanguage;

      return (
        matchesSearchQuery && matchesCategory && matchesStatus && matchesLanguage
      );
    }) || [];

    // Set total records for pagination
    setTotalRecords(filteredData.length);

    // Sort data if sort column is specified
    let sortedData = [...filteredData];
    if (sortColumn) {
      sortedData.sort((a, b) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];
        if (sortDirection === 'asc') {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue < bValue ? 1 : -1;
        }
      });
    }

    // Calculate pagination
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    
    // Set displayed data
    setDisplayData(sortedData.slice(startIndex, endIndex));
  }, [
    templates.templateData,
    searchQuery,
    selectedCategory,
    selectedStatus,
    selectedLanguage,
    currentPage,
    rowsPerPage,
    sortColumn,
    sortDirection
  ]);

  const handleClear = () => {
    setSearchQuery(DEFAULT_SEARCH_QUERY);
    setSelectedCategory(DEFAULT_CATEGORY);
    setSelectedStatus(DEFAULT_STATUS);
    setSelectedLanguage(DEFAULT_LANGUAGE);
    setCurrentPage(1); // Reset to first page when filters are cleared
  };

  return (
    <div className="container-fluid">
      <div className="row mb-3 align-items-center">
        <div className="col-md d-flex px-1">
          <div className="form-group" style={{ width: "100%" }}>
            <input
              type="text"
              placeholder="Search By Template Name"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                setCurrentPage(1); // Reset to first page on search
              }}
              className="form-control input-field-ui ps-5"
            />
            <img
              src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png"
              className="input-icon-seacrh"
              alt="search-icon"
            />
          </div>
        </div>

        <div className="col-md-2 px-1">
          <select
            value={selectedStatus}
            onChange={(e) => {
              setSelectedStatus(e.target.value);
              setCurrentPage(1);
            }}
            className="form-select input-field-ui"
          >
            <option value={DEFAULT_STATUS}>--Select Status--</option>
            <option value="approved">Approved</option>
            <option value="rejected">Rejected</option>
            <option value="Approval Pending">Pending</option>
          </select>
        </div>

        <div className="col-md-2 px-1">
          <select
            value={selectedCategory}
            onChange={(e) => {
              setSelectedCategory(e.target.value);
              setCurrentPage(1);
            }}
            className="form-select input-field-ui"
          >
            <option value={DEFAULT_CATEGORY}>--Select Category--</option>
            <option value="Marketing">Marketing</option>
            <option value="Utility">Utility</option>
            <option value="authentication">Authentication</option>
          </select>
        </div>

        <div className="col-md-2 px-1">
          <select
            value={selectedLanguage}
            onChange={(e) => {
              setSelectedLanguage(e.target.value);
              setCurrentPage(1);
            }}
            className="form-select input-field-ui"
          >
            <option value={DEFAULT_LANGUAGE}>--Select Language--</option>
            <option value="English">English</option>
            <option value="Arabic">Arabic</option>
          </select>
        </div>

        <div className="col-md-auto px-1">
          <button
            onClick={handleClear}
            className="btn btn-primary clear-search-btn"
          >
            Clear
          </button>
        </div>
      </div>

      <BackendDrivenDataTable
        columns={columns}
        data={displayData}
        page={currentPage}
        setPage={setCurrentPage}
        perPage={rowsPerPage}
        setPerPage={setRowsPerPage}
        totalRows={totalRecords}
        sortColumn={sortColumn}
        setSortColumn={setSortColumn}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
      />
    </div>
  );
};

export default CareCoordinateDeletedTemplates;