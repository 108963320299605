import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import WhatsAppAnalyticsBar from "./WhatsAppAnalyticsBar";
import WhatsAppAnalyticsPie from "./WhatsAppAnalyticsPie";
import CalendarDatePicker from "../../componenents/CalendarDatePicker";
import { decryptData, encryptData } from "../../utils/Utils";
import {
  fetch_count,
  fetch_carenavigator,
  fetch_admin_insights,
  fetch_admins,
  fetchDashboardList,
  fetch_agent_insights,
  fetch_unregistered_insights,
} from "../../../services/whatsapp/Service";
import WhatsAppAnalyticsBar2 from "./WhatsAppAnalyticsBar2";
import { LoaderLoader } from "../../utils/CommonLibrary";
import { set } from "firebase/database";

const AnalyticsDashboard = () => {
  const [loading, setLoading ] = useState(false);
  const [analyticsData, setAnalyticsData] = useState([]);
  const [seriesGraph, setSeriesGraph] = useState([]);
  const [adminGraph, setAdminGraph] = useState([]);
  const [agentGraph, setAgentGraph] = useState([]);
  const [unregisteredGraph, setUnregisteredGraph] = useState([]);
  

  
  

  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  const [adminsUsers, setAdminusers] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0); // Optional if provided by API

  const storedDates = localStorage.getItem('selectedDates') ? JSON.parse(decryptData(localStorage.getItem('selectedDates'))) : '';
  
      const [startDate, setStartDate] = useState(
          storedDates && storedDates.length === 4 ? moment(storedDates[0]) : moment()
      );
      const [endDate, setEndDate] = useState(
          storedDates && storedDates.length === 4 ? moment(storedDates[1]) : moment()
      );


  const [selectedDashboard, setSelectedDashboard] = useState('all');

  const [dashboardList, setDashboardList] = useState([]);
  

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });

  
  


  const getSortedData = (data) => {
    if (!sortConfig.key) return data;

    return [...data].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  };


  // Add this handler function
  const handleDateChange = ({ start, end }) => {
    setStartDate(start);
    setEndDate(end);
  };

  // Mock the localStorage for the date range picker
  useEffect(() => {
    // const mockStoredDates = [
    //   moment().subtract(7, "days"),
    //   moment(),
    //   moment().startOf("year"),
    //   moment(),
    // ];
    // localStorage.setItem(
    //   "selectedDates",
    //   encryptData(JSON.stringify(mockStoredDates))
    // );
    fetchDashboard();
  }, []);

  const fetchDashboard = async()=>{
    setLoading(true);
    try {
      const resp = await fetchDashboardList(); // Pass the current page to the API
      if (resp.data.code === 200) {
        setDashboardList(resp.data.data);       
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    setLoading(false);
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const fetchData = async (start, end) => {
    setLoading(true);
    try {
      const response = await fetch_count({ startDate: start, endDate: end, dashboardId :selectedDashboard }); // Pass the current page to the API
      if (response.data.code == 200 && response.data.data.insights.length > 0) {
        setAnalyticsData(response.data.data.insights);
      }
      // Assuming the API returns an array of data objects that you need for your dashboard
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    setLoading(false);
  };

  const fetch_carenavigator_insights = async (start, end) => {
    try {
      const response = await fetch_carenavigator({
        startDate: start,
        endDate: end,
        dashboardId :selectedDashboard,
      }); // Pass the current page to the API
      if (response.data.code == 200 && response.data.data) {
        setSeriesGraph(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const fetch_admin_insights_data = async (start, end) => {
    setLoading(true);
    try {
      const response = await fetch_admin_insights({
        startDate: start,
        endDate: end,
        dashboardId :selectedDashboard,
      }); // Pass the current page to the API
      console.log("admin response",response.data.data);
      if (response.data.code == 200 && response.data.data) {
        setAdminGraph(response.data.data);
        console.log("admin response",response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    setLoading(false);
  };

  const fetch_agent_insights_data = async (start, end) => {
    try {
      const responseRow = await fetch_agent_insights({
        startDate: start,
        endDate: end,
        dashboardId :selectedDashboard,
      }); // Pass the current page to the API
      if (responseRow.data.code == 200 && responseRow.data.data) {
        setAgentGraph(responseRow.data.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const fetch_admins_data = async (start, end, page = 1, limit = 10) => {
    setLoading(true);
    try {
      const response = await fetch_admins({
        startDate: start,
        endDate: end,
        page: page,
        limit: limit,
      }); // Pass the current page to the API
      if (response.data.code == 200 && response.data.data) {
        setAdminusers(response.data.data.list);
        setTotalRecords(response.data.data.total); // Assuming response includes `total`
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    setLoading(false);
  };

  
  const fetch_unregistered_insights_data = async (start, end) => {
    try {
      const responseunRow = await fetch_unregistered_insights({
        startDate: start,
        endDate: end,
        dashboardId :selectedDashboard,
      }); // Pass the current page to the API
      if (responseunRow.data.code == 200 && responseunRow.data.data) {
        setUnregisteredGraph(responseunRow.data.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };


  useEffect(() => {
    fetchData(formatDate(startDate), formatDate(endDate));

    //fetch_carenavigator_insights(formatDate(startDate), formatDate(endDate));

    fetch_admin_insights_data(formatDate(startDate), formatDate(endDate));

    fetch_agent_insights_data(formatDate(startDate), formatDate(endDate));

    fetch_unregistered_insights_data(formatDate(startDate), formatDate(endDate));

    // fetch_campaign_insights_data(formatDate(startDate), formatDate(endDate));
    
  }, [startDate, endDate, selectedDashboard]); // Trigger when either startDate or endDate changes

  useEffect(() => {
    fetch_admins_data(
      formatDate(startDate),
      formatDate(endDate),
      currentPage,
      pageLimit
    );
  }, [startDate, endDate, currentPage, pageLimit, selectedDashboard]); // Trigger when either startDate or endDate changes

 

  console.log(adminGraph, "adminGraph");
  console.log(adminGraph.adminCount, "seriesGraph.adminCount");
  console.log("selectedDashboard",selectedDashboard);
  

  return (
    <div className="container-fluid tw-mb-16">
      <LoaderLoader isLoading={loading} />
      <div className="tw-sticky tw-top-14 tw-mb-2 tw-bg-[#f1f6fc] tw-z-10 tw-border-b">
        <div className="tw-w-full  tw-pb-3">
          <div className="tw-flex tw-items-center tw-justify-between">
            <h4 className="tw-text-gray-600 tw-m-0">Engagement Analytics</h4>
            <div className="tw-w-[40%] tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-4">
              <select
                value={selectedDashboard}
                onChange={(e) => setSelectedDashboard(e.target.value)}
                className="form-select tw-rounded-xl"
                style={{
                  width: "45%",
                  padding: "10px 14px",
                  border: "1px solid #DCDCDC !important",
                }}
              >
                 <option value="all">All</option>
                {dashboardList?.map((option, index) => (
                  <option key={index} value={option.dashboardId}>
                    {option.dashboardName}
                  </option>
                ))}
              </select>
              <CalendarDatePicker
                startDate={startDate}
                endDate={endDate}
                onDateChange={handleDateChange}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="reports-programs mb-3">
        <div className="row align-items-center">
          <div className="col-md-auto">
            <img
              src="https://storage.googleapis.com/ksabupatest/dashboardresources/icons/programperformance.png"
              alt="Invited Members icon"
              style={{ height: "140px" }}
              className="img-fluid mb-0"
            />
          </div>
          <div className="col-md">
            <p className="mb-3">
              The Engagememt Analytics Dashboard provides key performance
              metrics for Engagement Admins and Agents, including total
              conversations, responses, pending responses, and opt-outs. It
              features visualizations like wait time, average response time,
              opt-out trends, and template usage. A performance table with
              detailed metrics and tooltips helps team leads monitor efficiency
              and member engagement.
            </p>
          </div>
        </div>
      </div>

      <div className="tw-grid tw-grid-cols-4 tw-gap-2 tw-bg-white tw-rounded-xl tw-shadow-md tw-text-center tw-p-2 tw-mt-2">
        {analyticsData.map((item, index) => (
          <div key={index} className="tw-px-1">
            <div className="tw-bg-[#f0f4ff] tw-rounded-[14px] tw-h-full">
              <div className="tw-p-2">
                <div className="tw-flex tw-flex-col">
                  <div className="tw-relative">
                    <h1 className="tw-text-2xl tw-font-bold tw-mb-1 tw-text-start tw-font-ibm-plex">
                      {item.value}
                    </h1>
                    <p className="tw-text-base tw-text-gray-500 tw-flex tw-items-center tw-justify-start">
                      {item.title}
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{item.tooltip}</Tooltip>}
                      >
                        <i className="bx bx-info-circle tw-ml-1 tw-cursor-pointer"></i>
                      </OverlayTrigger>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

{(selectedDashboard === 'OWh0NSsrOXpEZU5POXEyL21Td2tMZz09' || selectedDashboard==='all') && ( 
      <>
      <div className="row tw-my-4">
        <div className="col-md-12 mb-3">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>
                Average Response Count by Enrollment Admin
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">
                      Average Response Count by Enrollment Admin
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>

            {adminGraph.adminCount &&
              adminGraph.adminCount.dataValue.length > 0 ? (
              <WhatsAppAnalyticsBar2
                dataMonth={adminGraph.adminCount}
              />
            ) : (
              <div className="emty-column">
                <h5 className="emty-title-graph">
                  There are no data to display
                </h5>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="row tw-my-4">
        <div className="col-md-12 mb-3">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>
                Average Response Time by Enrollment Admin
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">
                      Average Response Time by Enrollment Admin
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>

            {adminGraph.adminResponseData?.dataValue.length > 0 ? (
              <WhatsAppAnalyticsBar
                dataMonth={adminGraph.adminResponseData}
              />
            ) : (
              <div className="emty-column">
                <h5 className="emty-title-graph">
                  There are no data to display
                </h5>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="row tw-my-4">
        <div className="col-md-12 mb-3">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>
                Average Response Count by Customer Engagement Agent
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">
                      Average Response Count by Customer Engagement Agent
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>

            {agentGraph.agentCount &&
            agentGraph.agentCount.dataValue.length > 0 ? (
              <WhatsAppAnalyticsBar2
                dataMonth={agentGraph.agentCount}
              />
            ) : (
              <div className="emty-column">
                <h5 className="emty-title-graph">There are no data to display</h5>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="row tw-my-4">
        <div className="col-md-12 mb-3">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>
                Average Response Time by Customer Engagement Agent
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">
                      Average Response Time by Customer Engagement Agent
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>

            {agentGraph.agentResponseData &&
            agentGraph.agentResponseData.dataValue.length > 0 ? (
              <WhatsAppAnalyticsBar
                dataMonth={agentGraph.agentResponseData}
              />
            ) : (
              <div className="emty-column">
                <h5 className="emty-title-graph">There are no data to display</h5>
              </div>
            )}
          </div>
        </div>
      </div>
      </>
)}


{(selectedDashboard === 'OWh0NSsrOXpEZU5POXEyL21Td2tMZz09' || selectedDashboard==='all') && ( 
      <>
      <div className="row tw-my-4">
        <div className="col-md-12 mb-3">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>
                Average Response Count by Customer Support Agent
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">
                      Average Response Count by Customer Support Agent
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>

            {unregisteredGraph.unregistedCount &&
            unregisteredGraph.unregistedCount.dataValue.length > 0 ? (
              <WhatsAppAnalyticsBar2
                dataMonth={unregisteredGraph.unregistedCount}
              />
            ) : (
              <div className="emty-column">
                <h5 className="emty-title-graph">
                  There are no data to display
                </h5>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="row tw-my-4">
        <div className="col-md-12 mb-3">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>
                Average Response Time by Customer Support Agent
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">
                      Average Response Time by Customer Support Agent
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>

            {unregisteredGraph.unregistedResponseData &&
            unregisteredGraph.unregistedResponseData.dataValue.length > 0 ? (
              <WhatsAppAnalyticsBar
                dataMonth={unregisteredGraph.unregistedResponseData}
              />
            ) : (
              <div className="emty-column">
                <h5 className="emty-title-graph">
                  There are no data to display
                </h5>
              </div>
            )}
          </div>
        </div>
      </div>
      </>
)}
     

      {/* Template Breakdown Pie Chart */}
      <div className="row tw-my-4">
        <div className="col-md-12 mb-3">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>
                Template-wise Breakdown
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">
                      Distribution of template usage
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>
            {adminGraph.templateResult &&
              adminGraph.templateResult.length > 0 ? (
              <WhatsAppAnalyticsPie series={adminGraph.templateResult} />
            ) : (
              <div className="emty-pie">
              <h5 className="emty-title-graph">There are no data to display</h5>
          </div>
            )}
          </div>
        </div>
        {/* <div className="col-md-6 mb-3">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>
                Template-wise Breakdown
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">
                      Distribution of template usage
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>
            {loading ? (
              <div className="tw-flex tw-items-center tw-justify-center tw-text-xl tw-font-semibold tw-text-gray-500 tw-animate-pulse">
                Loading...
              </div>
            ) : unregisteredGraph.templateUnregisteredResult &&
            unregisteredGraph.templateUnregisteredResult.length > 0 ? (
              <WhatsAppAnalyticsPie series={unregisteredGraph.templateUnregisteredResult} />
            ) : (
              <div className="emty-pie">
              <h5 className="emty-title-graph">There are no data to display</h5>
          </div>
            )}
          </div>
        </div> */}
      </div>
     
    </div>
  );
};

export default AnalyticsDashboard;
