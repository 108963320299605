import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import './LabRequestForms.css';
import { fetchUserProfile, fetchFormData, saveLabRequestMetaDoc } from '../services/Service';
import axios from "axios";
import Select from 'react-select';
import { MultiSelect } from 'react-multi-select-component';
import { formatDateTime } from '../../../met/utils/CommonLibrary';
import DatePicker from 'react-datepicker';

const LabRequestMetaDocForms = ({ closeModal, selectedServiceRecord, callHomeBasedServicesHistory,membershipNoMember }) => {

    const qsParams = useParams();
    const userId = qsParams.memberId;

    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [labProviderList, setLabProviderList] = useState([]);
    const [partner, setPartner] = useState([]);
    const [disabledButton, setDisabledButton] = useState(true);
    const [cityList, setCityList] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [serviceList, setServiceList] = useState([]);

    const [formData, setFormData] = useState({
        userId: userId,
        selectedServiceRecord: selectedServiceRecord,
        membership: '',
        name: '',
        approvalNumber: '',
        mobileNumber: '',
        deductible: '',
        partnerClinic: '',
        labProvider: '',
        labProviderName: '',
        city: '',
        district: '',
        remarks: '',
        requestedLabTest: '',
        nationality: '',
        selectedUserNames: '',
        address: '',
        selectedDate: '',
        viewDate: '',
        cityId: ''
    });

    useEffect(() => {
        fetchUserData();
        dropdownData();
    }, [])


    const fetchUserData = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        setDisabledButton(true);
        await fetchUserProfile(userId,'metaService')
            .then((response) => {
                if (response.data.code === 200) {
                    setFormData((prev) => ({ ...prev, membership: response.data.data.info.membershipNumber, name: response.data.data.info.memberName, mobileNumber: response.data.data.info.mobile, 
                         nationality: response.data.data.info.nationality, address: response.data.data.info.address }));
                    setDisabledButton(false);
                } else {
                    setError(response.data.data.message);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const dropdownData = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchFormData('',membershipNoMember)
            .then((response) => {
                if (response.data.code === 200) {
                    setPartner(response.data.data.partner);
                    setCityList(response.data.data.city);
                    setServiceList(response.data.data.labTest);
                } else {
                    setError(response.data.data.message);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }


    const handleChange = async (e) => {
        const { name, value  } = e.target;
        const updatedFormData = { ...formData, [name]: value };

        if (name === "labProvider") {
            updatedFormData.labProviderName = e.target.selectedOptions[0].text;
        }
        setFormData(updatedFormData);
        setError('');
    };

    const validateForm = () => {
        const mandatoryFields = ['membership', 'name', 'mobileNumber', 'deductible', 'partnerClinic', 'labProvider', 'city', 'requestedLabTest', 'nationality','selectedDate'];

        for (let field of mandatoryFields) {
            if ((field === 'city')) {
                if (formData[field] == "") {
                    setError('Enter all mandatory fields');
                    return false;
                }
            }else if (field === 'selectedDate') {
                if (formData[field] == "" || formData[field] == undefined) {
                    setError('Enter all mandatory fields');
                    return false;
                }
            }else if (!formData[field].trim()) {
                setError('Enter all mandatory fields');
                return false;
            }
        }

        return true;
    };

    const setSelctedDate = async (date) => {
        setFormData({
            ...formData,
            ['viewDate']: date,
            ['selectedDate']: formatDateTime(date),
        });

        setError('');
    };

    const handleChangeCity = (selectedOption) => {
        fetchCategoryList(selectedOption.value);
        setFormData({
            ...formData,
            ['city']: selectedOption.label,
            ['cityId']: selectedOption.value,
        });
    };

    const fetchCategoryList = async (cityName) => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchFormData(cityName,formData['membership'])
        .then((response) => {
            if (response.data.code === 200) {
                setLabProviderList(response.data.data.lab);
            } else {
                setError(response.data.data.message);
            }
        }).catch(function (error) {
            console.log(error);
        });
        return () => request.cancel();
    }

    const handleSubmit = async (event) => {
        
        setError('');
        event.preventDefault();
        if (validateForm()) {
            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            }
            setDisabledButton(true);
            await saveLabRequestMetaDoc(formData)
                .then((response) => {
                    if (response.data.code === 200) {
                        setMessage(response.data.data.message);
                        callHomeBasedServicesHistory();
                        setTimeout(() => {
                            closeModal();
                        }, 1000);
                    } else {
                        setMessage('');
                        setError(response.data.data.message);
                        setDisabledButton(false);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        }
    }

    const handleChangeService = (selected) => {
        setSelectedOptions(selected);
        const selectedValues = selected.map(option => option.value).join(',');
        const selectedValuesNew = selected.map(option => option.value).join(',,');
        setFormData({ ...formData, requestedLabTest: selectedValues, selectedUserNames: selectedValuesNew });
    };
    const selectedUserNamesTemp = selectedOptions.map(option => option.label).join(', ');

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedTime = new Date(time);

        // Allow only times equal to or greater than the current time for today
        return selectedTime.getTime() >= currentDate.getTime();
    };

    useEffect(() => {
        if (formData['viewDate']) {
          const now = new Date();
          if (formData['viewDate'] < now) {
            const roundedNow = roundToNext15Minutes(now);
            // setSelectedDate(roundedNow);
            setFormData({
                ...formData,
                ['viewDate']: roundedNow,
                ['selectedDate']: formatDateTime(roundedNow),
            });
          }
        }
    }, [formData['viewDate']]);

    const roundToNext15Minutes = (date) => {
        const roundedDate = new Date(date);
        const minutes = roundedDate.getMinutes();
        const remainder = 15 - (minutes % 15);
        roundedDate.setMinutes(minutes + remainder);
        roundedDate.setSeconds(0);
        roundedDate.setMilliseconds(0);
        return roundedDate;
    };

    return (
        <>
            <div className='col-md-12'>
                <div className='card card-task-appoint'>
                    <div className='card-body'>
                        <div className='card-text'>
                            <form id="medicationForm" onSubmit={handleSubmit} style={{ width: "100%" }}>

                                <div className='row'>

                                    <div className='col-md-6 mb-2 mt-2'>
                                        <div className='row'>
                                            <div className='col-md-6 mb-4'>
                                                <label className="form-label">Name
                                                </label>
                                                <h5 name="name">{formData['name']}</h5>
                                            </div>

                                            <div className='col-md-6 mb-4'>
                                                <label className="form-label">Membership
                                                </label>
                                                <h5 name="membership">{formData['membership']}</h5>
                                            </div>

                                            <div className='col-md-6 mb-3'>
                                                <label className="form-label">Mobile Number
                                                </label>
                                                <input type="text" name="mobileNumber" className='form-control control-input-ui' value={formData['mobileNumber']} onChange={handleChange} />
                                            </div>

                                            <div className='col-md-6 mb-3'>
                                                <label className="form-label">Partner Clinic
                                                    <span className='text-danger'>*</span>
                                                </label>
                                                <select className="form-select select-input-ui" name="partnerClinic" id="partnerClinic" value={formData['partnerClinic']} onChange={handleChange} >
                                                    <option value="">-- Select Partner Clinic --</option>
                                                    {partner.map(option => (
                                                        <option key={option.id} value={option.id}>{option.partnerName}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className='col-md-6 mb-3'>
                                                <label className="form-label">District</label>
                                                <input type="text" name="district" className='form-control control-input-ui' value={formData['district']} onChange={handleChange} />
                                            </div>

                                            <div className='col-md-6 mb-3'>
                                                <label className="form-label">Nationality
                                                    <span className='text-danger'>*</span>
                                                </label>
                                                <select className="form-select select-input-ui" name="nationality" id="nationality" value={formData['nationality']} onChange={handleChange} >
                                                    <option value="">-- Select Nationality --</option>
                                                    <option value="Saudi Arabian">Saudi Arabian</option>
                                                    <option value="Non Saudi Arabian">Non Saudi Arabian</option>
                                                </select>
                                            </div>
                                            <div className='col-md-6 mb-3'>
                                                <label className="form-label">Approval Number
                                                </label>
                                                <input type="text" name="approvalNumber" className='form-control control-input-ui' value={formData['approvalNumber']} onChange={handleChange} />
                                            </div>

                                            <div className='col-md-6 mb-3'>
                                                <label className="form-label">Deductible
                                                    <span className='text-danger'>*</span>
                                                </label>
                                                <input type="text" name="deductible" className='form-control control-input-ui' value={formData['deductible']} onChange={handleChange} />
                                            </div>

                                        </div>
                                    </div>

                                    <div className='col-md-6 mb-3 '>
                                        <div className='row'>

                                            <div className='col-md-6 mb-3'>
                                                <label className="form-label">City
                                                    <span className='text-danger'>*</span>
                                                </label>
                                                <Select
                                                    value={cityList.find(option => option.label === formData.city) || null}
                                                    onChange={handleChangeCity}
                                                    options={cityList}
                                                    isSearchable={true}
                                                    placeholder="Select City"
                                                    className="form-control select-input-ui select-input-ui-select"
                                                />
                                            </div>
                                            

                                            <div className='col-md-6 mb-3'>
                                                <label className="form-label">Lab Provider
                                                    <span className='text-danger'>*</span>
                                                </label>
                                                <select className="form-select select-input-ui" name="labProvider" id="labProvider" value={formData['labProvider']} onChange={handleChange} >
                                                    <option value="">-- Select Lab Provider --</option>
                                                    {labProviderList?.map(option => (
                                                        <option key={option.id} value={option.id}>{option.labName}</option>
                                                    ))}
                                                </select>
                                            </div>



                                            <div className='col-md-12 mb-3'>
                                                <label className="form-label">Remarks
                                                </label>
                                                <input type="text" name="remarks" className='form-control control-input-ui' value={formData['remarks']} onChange={handleChange} />
                                            </div>

                                            <div className='col-md-12 mb-3'>
                                                <label className="form-label">Preferred Appointment Date
                                                    <span className='text-danger'>*</span>
                                                </label>
                                                <div className="date-picker-wrapper" style={{ position: 'relative' }}>
                                                    <DatePicker
                                                        selectsRange={false}
                                                        selected={formData['viewDate']}
                                                        onChange={(date) => setSelctedDate(date)}
                                                        // selected={selectedDate}
                                                        // onChange={handleDateChange}
                                                        showTimeSelect
                                                        timeFormat="h:mm aa"
                                                        timeIntervals={15}
                                                        dateFormat="dd MMMM yyyy, h:mm aa"
                                                        minDate={new Date()}
                                                        filterTime={filterPassedTime}
                                                        // minTime={formData['viewDate'] > new Date() ? new Date(new Date().setHours(0,0,0,0)) : new Date()}
                                                        // maxTime={new Date().setHours(23, 59, 59)}
                                                        placeholderText="Select Date and Time"
                                                        className="form-control input-field-ui"
                                                        isClearable={false}
                                                        onKeyDown={(e) => {
                                                            e.preventDefault();
                                                        }}
                                                    />
                                                    <i className='bx bx-calendar'
                                                        style={{
                                                            position: 'absolute',
                                                            right: '10px',
                                                            top: '50%',
                                                            transform: 'translateY(-50%)',
                                                            pointerEvents: 'none',
                                                            fontSize: '20px',
                                                            color: '#666'
                                                        }}></i>
                                                    </div>
                                            </div>

                                            <div className='col-md-12 mb-3'>
                                                <label className="form-label">Requested Lab Test
                                                    <span className='text-danger'>*</span>
                                                </label>
                                                {/* <textarea type="text" name="requestedLabTest" className='form-control control-input-ui' style={{ height: "130px" }} rows="7" value={formData['requestedLabTest']} onChange={handleChange}></textarea> */}
                                                <MultiSelect
                                                    options={serviceList}
                                                    value={selectedOptions}
                                                    onChange={handleChangeService}
                                                    labelledBy="Select"
                                                    hasSelectAll={false}
                                                    disableSearch={false}
                                                    overrideStrings={{
                                                        selectAll: 'Select All',
                                                        allItemsAreSelected: 'All items are selected',
                                                        selectSomeItems: 'Select items',
                                                    }}
                                                    className="form-select select-input-ui select-input-ui-select"
                                                    name="requestedLabTest"
                                                    id="requestedLabTest"

                                                />

                                            </div>
                                            <div className='col-md-12 mb-3'>
                                                {selectedUserNamesTemp}
                                            </div>
                                        </div>


                                    </div>
                                    <div className='col-md-12 text-center mt-2'>
                                        <div className={`${error != "" ? "errorDiv" : "hide"}`}>{error}</div>
                                        <div className={`${message != "" ? "messageDiv" : "hide"}`}>{message}</div>
                                        <Button variant="primary" type="submit" className="btn btn-primary save-btn me-3" disabled={disabledButton}>Save Lab Request</Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default LabRequestMetaDocForms;