import React, { useState } from 'react';
import ContentCNReportsTable from './ContentCNReportsTable';
import ContentCNReportsTableV2 from './ContentCNReportsTableV2';

function ContentCNReports() {


    return (
        <>

            <section className='CareNavigatorReports'>
                <div className="row align-items-center pb-3 sticky-top-bar">
                    <div className="col-md-12">
                        <h1 className="main-title">Care Navigator Reports</h1>
                    </div>
                </div>

                <div className='reports-programs mb-3'>
                    <div className="row align-items-center">
                        <div className="col-md-auto">
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/daz4c/g4h2h47hv2.png" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
                        </div>
                        <div className="col-md">
                            <p className="mb-0">The system provides robust reporting capabilities, allowing the extraction of raw data and reports in XLS format.</p>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row align-items-center pb-3">
                        <div className='comntent-CareNavigator mb-3'>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="table-responsive ContentCNReportsTable">
                                        <ContentCNReportsTableV2 />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default ContentCNReports;
