import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { memberContentNabed, memberAssignJourney, memberSearchJourney, memberAssignJourneyPreview } from '../services/Service';
import axios from "axios";
import TreeStructure from './TreeStructure';
import { CustomFieldLevelLoader } from '../../../utils/CommonLibrary';
import { Link } from 'react-router-dom';
import ProgressbarEdu from './ProgressbarEdu';

const EducationContent = (props) => {
    
    const membershipNo = props.membershipNo;
    const userId = props.userId;
    const memberLanguage = props.language;

    const [conditionsData, setConditionsData] = useState([]);
    const [proceduresData, setProceduresData] = useState([]);
    const [selectedConditions, setSelectedConditions] = useState([]);
    const [selectedProcedures, setSelectedProcedures] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessageSave, setErrorMessageSave] = useState('');
    
    const [clearSelectionsTrigger, setClearSelectionsTrigger] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(false);
    const [showConditionList, setShowConditionList] = useState(false);
    const [showProceduresList, setShowProceduresList] = useState(false);
    const [showModalNew, setShowModalNew] = useState(false);
    const [previewCondition, setPreviewCondition] = useState([]);
    const [previewProcedures, setPreviewProcedures] = useState([]);
    const [triggerSubmit, setTriggerSubmit] = useState(null);
    const [showConditionError, setShowConditionError] = useState('');
    const [showProceduresError, setShowProceduresError] = useState('');

    const [selectedProcedureNew, setSelectedProcedureNew] = useState([]);
    const [selectedTopicNew, setSelectedTopicNew] = useState([]);
    const [selectedContentsNew, setSelectedContentsNew] = useState([]);

    const [selectedProcedureTwo, setSelectedProcedureTwo] = useState([]);
    const [selectedTopicTwo, setSelectedTopicTwo] = useState([]);
    const [selectedContentsTwo, setSelectedContentsTwo] = useState([]);


    const [formData, setFormData] = useState({
        conditionsText: '',
        procedureText : ''
    });
    

    useEffect(() => {
        contentNabed('conditions');
        contentNabed('procedures');
    }, [])
    
    const contentNabed = async (requestFrom) => {
        const request = axios.CancelToken.source();
        let cancelToken = {
            cancelToken: request.token
        }
        await memberContentNabed(membershipNo,userId,requestFrom, cancelToken)
                .then((response) => {
                    if(response.data.code == 200){
                        if(requestFrom == 'conditions'){
                            setConditionsData(response.data.data.conditions);
                            setShowConditionList(true);
                        }
                        if(requestFrom == 'procedures'){
                            setProceduresData(response.data.data.procedures);
                            setShowProceduresList(true);
                        }
                        setShowButton(true);
                    }else{
                        setErrorMessage(response.data.data.message);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
    }

    const handleClose = () =>{
        setErrorMessageSave('');
        setShowModalNew(false);
    }

    const clearButton = (requestFrom) => {
        if (requestFrom === "conditions") {
            setFormData((prevData) => ({
                ...prevData,
                conditionsText: ""
            }));
        }
        if (requestFrom === "procedures") {
            setFormData((prevData) => ({
                ...prevData,
                procedureText: ""
            }));
        }
        setTriggerSubmit(requestFrom);
    };

    useEffect(() => {
        if (triggerSubmit) {
            searchSubmit('',triggerSubmit);
            setTriggerSubmit(null);
        }
    }, [triggerSubmit]);

    const handleSubmit = async () => {
        setShowConditionError('');
        setErrorMessage('');
        
        if (selectedConditions.contents.length == 0 && selectedProcedures.contents.length == 0 && selectedConditions.procedure.length == 0 &&
             selectedProcedures.procedure.length == 0 && selectedConditions.topics.length == 0 && selectedProcedures.topics.length == 0) {
            setErrorMessage('Please select at least one item from Conditions or Procedures.');
            setTimeout(() => setErrorMessage(false), 2000);
            return;
        }
       
        const payload = {
            membershipNo: membershipNo,
            userId: userId,
            conditions: selectedConditions,
            procedures: selectedProcedures,
        };
        
        setShowButton(false);
        const request = axios.CancelToken.source();
        let cancelToken = {
            cancelToken: request.token
        };
        await memberAssignJourneyPreview(payload, cancelToken)
            .then((response) => {
                setShowButton(true);
                if(response.data.code == 200){
                    setShowSaveButton(true);
                    setShowModalNew(true);
                    setPreviewCondition(response.data.data.conditions);
                    setPreviewProcedures(response.data.data.procedures);
                }else{
                    setErrorMessage(response.data.data.message);
                    setTimeout(() => setErrorMessage(false), 2000);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    };


    const handleFinalSubmit = async () => {
        setErrorMessageSave('');
        setShowConditionError('');
        setShowSaveButton(false);
        
        const payload = {
            membershipNo: membershipNo,
            userId: userId,
            conditions: selectedConditions,
            procedures: selectedProcedures,
            memberLanguage:memberLanguage,
        };
    
        const request = axios.CancelToken.source();
        let cancelToken = {
            cancelToken: request.token
        };
        await memberAssignJourney(payload, cancelToken)
            .then((response) => {
                setShowButton(true);
                if(response.data.code == 200){

                    const allProcedures = document.querySelectorAll('[id^="procedure-"]');
                    allProcedures.forEach(element => {
                        element.classList.remove('selected-checkbox');
                    });

                    const allTopics = document.querySelectorAll('[id^="topic-"]');
                    allTopics.forEach(element => {
                        element.classList.remove('selected-checkbox');
                    });

                    setClearSelectionsTrigger((prev) => !prev);
                    setErrorMessage('');
                    setSuccessMessage(response.data.data.message);
                    props.refreshPageMember();
                }else{
                    setShowSaveButton(true);
                    setErrorMessageSave(response.data.data.message);
                    setTimeout(() => setErrorMessage(false), 2000);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    };

    const handleSelectedItems = (type, selectedItems) => {
        if (type === 'conditions') {
            setSelectedConditions(selectedItems);
        } else if (type === 'procedures') {
            setSelectedProcedures(selectedItems);
        }
    };

    const searchSubmit = async (e,requestFrom) => {
        
        if(e == 'buttonclick' || ((e.key === "Enter") && ((requestFrom == 'conditions' && formData['conditionsText'] != '') || (requestFrom == 'procedures' && formData['procedureText'] != '')) || (requestFrom == 'conditions' && formData['conditionsText'].length == 0 && (e.key === "Backspace" || e.key === "Delete"))
             || (requestFrom == 'procedures' && formData['procedureText'].length == 0 && (e.key === "Backspace" || e.key === "Delete")) || e == '')){

                setErrorMessage('');
                
                if(requestFrom == 'conditions'){
                    setShowConditionError('');
                    setConditionsData('');
                    setSelectedConditions('');
                    setShowConditionList(false);
                }
                
                if(requestFrom == 'procedures'){
                    setShowProceduresError('');
                    setProceduresData('');
                    setSelectedProcedures('');
                    setShowProceduresList(false);
                }

                const payload = {
                    membershipNo: membershipNo,
                    userId: userId,
                    requestFrom: requestFrom,
                    conditions: selectedConditions,
                    procedures: selectedProcedures,
                };
            
                setShowButton(false);
                const request = axios.CancelToken.source();
                let cancelToken = {
                    cancelToken: request.token
                };
                await memberSearchJourney(payload,formData, cancelToken)
                    .then((response) => {
                        setShowButton(true);
                        if(response.data.code == 200){
                            
                            if(requestFrom == 'conditions'){
                                if(response.data.data.showConditions == "1"){
                                    setConditionsData(response.data.data.conditions);
                                    setShowConditionList(true);

                                    setSelectedProcedureNew(response.data.data.conditionsSelect.procedure);
                                    setSelectedTopicNew(response.data.data.conditionsSelect.topics);
                                    setSelectedContentsNew(response.data.data.conditionsSelect.contents);
                                }else{
                                    setShowConditionError(response.data.data.conditions)
                                }
                            }
                            
                            if(requestFrom == 'procedures'){
                                if(response.data.data.showProcedures == "1"){
                                    setProceduresData(response.data.data.procedures);
                                    setShowProceduresList(true);

                                    setSelectedProcedureTwo(response.data.data.proceduresSelect.procedure);
                                    setSelectedTopicTwo(response.data.data.proceduresSelect.topics);
                                    setSelectedContentsTwo(response.data.data.proceduresSelect.contents);
                                }else{
                                    setShowProceduresError(response.data.data.procedures)
                                }
                            }
                        }else{
                            setErrorMessage(response.data.data.message);
                            setTimeout(() => setErrorMessage(false), 2000);
                        }
                    }).catch(function (error) {
                        console.log(error);
                    });
                return () => request.cancel();
        }
    };


    const handleChange = async (e) => {

        const { name, value } = e.target;

        setErrorMessage('');
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const [showModalAssign, setShowModalAssign] = useState(false);
    const handleAssigClose = () => {
        setShowModalAssign(false);
    };

    const [showModalReview, setShowModalReview] = useState(false);
    const handleReviewClose = () => {
        setShowModalReview(false);
    };

    const [showModalPreviewTP, setShowModalPreviewTP] = useState(false);
    const handlePreviewTPClose = () => {
        setShowModalPreviewTP(false);
    };

    
    return (
        <>
            <Modal className="boxEducation" id="externalMemberSearch" show={props.showEducationModal} onHide={props.closeEducationModal} size="xl" backdrop="static" keyboard={false} fullscreen={false}>
                <Modal.Header closeButton>
                    <h1 className='modal-title'>Educational Journey</h1>
                </Modal.Header>
                <Modal.Body>
                    {/* <div className='profile-box-n1 mb-4'>
                         <h1 className='dashboard-graph-title mb-2'>Recommended journeys based on the ICD/CPT codes</h1>
                        <div className='row'>

                            <div className='col-md-5'>
                                <div className='content-ProgramPerformance mt-2'>
                                    <div className='row'>
                                        <div className='col-auto'>
                                            <img src="https://storage.googleapis.com/ksabupatest/2025/01/08/c46j6/74naz4nsnh.jpeg" alt="" className="profile-edu-img"/>
                                        </div>
                                        <div className='col ps-0'>
                                         <div className='row'>
                                            <div className='col-7 pe-0'>
                                                <h6 className='profile-edu-title mb-3'>High blood cholesterd</h6>
                                                <div className=' btn-edu mt-1'>Recommended by System</div>
                                                <div className=' btn-edu mt-1'>E 13 (High Blood Cholestoll)</div>
                                            </div>
                                            <div className='col-5'>
                                                <div className='btn-edu-box'>
                                                <button className='btn btn-edu-1 mb-1' onClick={() => setShowModalAssign(true)}>Assign</button>
                                                <button className='btn btn-edu-1 mb-1' onClick={()=> setShowModalReview(true)}>Review</button>
                                                <h6 className='profile-edu-subtitle mt-0'>Journey Topics <span>20</span></h6>
                                            </div>
                                        </div>
                                         </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-5'>
                                <div className='content-ProgramPerformance mt-2'>
                                    <div className='row'>
                                        <div className='col-auto'>
                                            <div className='profile-edu-img'>TP</div>
                                        </div>
                                        <div className='col ps-0'>
                                         <div className='row'>
                                            <div className='col-7 pe-0'>
                                                <h6 className='profile-edu-title mb-3'>TAVi proceedure</h6>
                                                <div className=' btn-edu mt-1'>Recommended by System</div>
                                                <div className=' btn-edu mt-1'>E 13 (High Blood Cholestoll)</div>
                                            </div>
                                            <div className='col-5'>
                                                <div className='btn-edu-box'>
                                                <button className='btn btn-edu-1 mb-1' onClick={() => setShowModalAssign(true)}>Assign</button>
                                                <button className='btn btn-edu-1 mb-1' onClick={()=> setShowModalReview(true)}>Review</button>
                                                <h6 className='profile-edu-subtitle mt-0'>Journey Topics <span>20</span></h6>
                                            </div>
                                        </div>
                                         </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div> */}

                    {/* <div className='profile-box-n1 mb-4'>
                         <h1 className='dashboard-graph-title mb-2'>Member history</h1>
                        <div className='row'>

                            <div className='col-md-5'>
                                <div className='content-ProgramPerformance mt-2'>
                                    <div className='row'>
                                        <div className='col-auto'>
                                            <div className='profile-edu-progress-bar'>
                                                <div className='profile-edu-progress'>
                                                    <ProgressbarEdu/>
                                                </div>
                                            </div>
                                           
                                            <img src="https://storage.googleapis.com/ksabupatest/2025/01/08/c46j6/74naz4nsnh.jpeg" alt="" className="profile-edu-img"/>
                                        </div>
                                        <div className='col ps-0'>
                                         <div className='row'>
                                            <div className='col-7 pe-0'>
                                                <h6 className='profile-edu-title mb-3'>Knee Replacement Surgery</h6>
                                                <div className=' btn-edu mt-1'>Recommended by System</div>
                                                <div className=' btn-edu mt-1'>E 13 (High Blood Cholestoll)</div>
                                            </div>
                                            <div className='col-5'>
                                                <div className='btn-edu-box'>
                                                <button className='btn btn-edu-1 mb-1'>Notify</button>
                                                <button className='btn btn-edu-1 mb-1' onClick={()=> setShowModalPreviewTP(true)}>Preview</button>
                                                <h6 className='profile-edu-subtitle mt-0'>Remaining Topics <span>20</span></h6>
                                                <h6 className='profile-edu-subtitle mt-0'>Journey Topics <span>20</span></h6>
                                            </div>
                                        </div>
                                         </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-5'>
                                <div className='content-ProgramPerformance mt-2'>
                                    <div className='row'>
                                        <div className='col-auto'>
                                            <img src="https://appcdn.goqii.com/user/user/l_1437644704.png" alt="" className="profile-edu-img"/>
                                        </div>
                                        <div className='col ps-0'>
                                         <div className='row'>
                                            <div className='col-7 pe-0'>
                                                <h6 className='profile-edu-title mb-3'>Consultation Summary attachments 16/12/2024</h6>
                                                <div className=' btn-edu mt-1'>Recommended by System</div>
                                            </div>
                                            <div className='col-5'>
                                                <div className='btn-edu-box'>
                                                <button className='btn btn-edu-1 mb-1' onClick={() => setShowModalAssign(true)}>Assign</button>
                                                <button className='btn btn-edu-1 mb-1' onClick={()=> setShowModalReview(true)}>Review</button>
                                                <h6 className='profile-edu-subtitle mt-0'>Assigned Topics <span>20</span></h6>
                                                <h6 className='profile-edu-subtitle mt-0'>Journey Topics <span>20</span></h6>
                                            </div>
                                        </div>
                                         </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div> */}

                    <div className='row mb-3'>
                        <div className='col-md-6'>
                            <h1 className='dashboard-graph-title mb-2'>Conditions</h1>
                            <div className='tw-flex tw-flex-row tw-items-center tw-gap-2'>
                                <div className='tw-w-[85%]'>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            placeholder="Search by keyword/ICD Code"
                                            name="conditionsText"
                                            value={formData['conditionsText']}
                                            onChange = {handleChange}
                                            onKeyUp = {(e) => searchSubmit(e,'conditions')}
                                            className="form-control input-field-ui ps-5"
                                        />
                                        {formData["conditionsText"] && (
                                            <button
                                                onClick={(e) => clearButton('conditions')}
                                                style={{
                                                    position: "absolute",
                                                    right: "10px",
                                                    top: "50%",
                                                    transform: "translateY(-50%)",
                                                    background: "none",
                                                    border: "none",
                                                    cursor: "pointer",
                                                    fontSize: "1rem",
                                                }}
                                            >
                                                ✖
                                            </button>
                                        )}
                                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                                    </div>
                                </div>
                                <button className='btn btn-primary save-btn' onClick={(e) => searchSubmit('buttonclick','conditions')}>
                                    Search
                                </button>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <h1 className='dashboard-graph-title mb-2'>Procedures</h1>
                            <div className='tw-flex tw-flex-row tw-items-center tw-gap-2'>
                                <div className='tw-w-[85%]'>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            placeholder="Search by keyword/CPT Code"
                                            name="procedureText"
                                            value={formData['procedureText']}
                                            onChange = {handleChange}
                                            onKeyUp = {(e) => searchSubmit(e,'procedures')}
                                            className="form-control input-field-ui ps-5"
                                        />
                                        {formData["procedureText"] && (
                                            <button
                                                onClick={(e) => clearButton('procedures')}
                                                style={{
                                                    position: "absolute",
                                                    right: "10px",
                                                    top: "50%",
                                                    transform: "translateY(-50%)",
                                                    background: "none",
                                                    border: "none",
                                                    cursor: "pointer",
                                                    fontSize: "1rem",
                                                }}
                                            >
                                                ✖
                                            </button>
                                        )}
                                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                                    </div>
                                </div>
                                <button className='btn btn-primary save-btn' onClick={(e) => searchSubmit('buttonclick','procedures')}>
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                    
                    <div className='row'>
                        <div className='col-md-6' style={{maxHeight:"70vh", overflowX:"hidden", overflowY:"scroll"}} >
                            {
                                showConditionList ? 
                                    conditionsData && (
                                        <TreeStructure
                                            contentData={conditionsData}
                                            onSelectionChange={(selectedItems) => handleSelectedItems('conditions', selectedItems)}
                                            clearSelectionsTrigger={clearSelectionsTrigger}
                                            selectedProcedureNew = {selectedProcedureNew}
                                            selectedTopicNew = {selectedTopicNew}
                                            selectedContentsNew = {selectedContentsNew}
                                        />
                                    )
                                :
                                (
                                    showConditionError ? 
                                        <>
                                            <div className={`${showConditionError != "" ? "errorDiv" : "hide"}`} style={{ fontWeight: "400 !important" }}>{showConditionError}</div>
                                        </>
                                    :
                                    !showConditionError && !errorMessage && <CustomFieldLevelLoader />
                                )
                            }
                        </div>
                        
                        <div className='col-md-6' style={{maxHeight:"70vh", overflowX:"hidden", overflowY:"scroll"}}>
                            {
                                showProceduresList ? 
                                    proceduresData && (
                                        <TreeStructure  
                                            contentData={proceduresData}
                                            onSelectionChange={(selectedItems) => handleSelectedItems('procedures', selectedItems)}
                                            clearSelectionsTrigger={clearSelectionsTrigger}
                                            selectedProcedureNew = {selectedProcedureTwo}
                                            selectedTopicNew = {selectedTopicTwo}
                                            selectedContentsNew = {selectedContentsTwo}
                                        />
                                    )
                                :
                                (
                                    showProceduresError ? 
                                        <>
                                            <div className={`${showProceduresError != "" ? "errorDiv" : "hide"}`} style={{ fontWeight: "400 !important" }}>{showProceduresError}</div>
                                        </>
                                    :
                                    !showProceduresError && !errorMessage &&  <CustomFieldLevelLoader />
                                )
                            }
                        </div>
                    </div>
                    <div className={`${errorMessage != "" ? "errorDiv" : "hide"}`} style={{ fontWeight: "400 !important" }}>{errorMessage}</div>
                    <div className={`${successMessage != "" ? "messageDiv" : "hide"}`} style={{ fontWeight: "400 !important" }}>{successMessage}</div>
                    <div className='row mt-3'>
                        <div className='col-md-12 text-center'>
                            {
                                (showConditionList || showProceduresList) && (
                                    showButton ?
                                        <button className='btn btn-primary save-btn' onClick={handleSubmit}>
                                            Preview Selection
                                        </button>
                                        :
                                        <CustomFieldLevelLoader />
                            )}
                        </div>
                    </div>
                    
                </Modal.Body>
            </Modal>


            {(previewCondition.length > 0 || previewProcedures.length > 0) && <Modal dialogClassName="modal-xl modal-dialog-centered div-10p" style={{width:"100%", paddingRight:"0"}} show={showModalNew} onHide={handleClose} id="showListDataTable" backdrop="static" keyboard={false}>
                <div className='modal-backdrop modulepups'>
                <div className="modal-content modal-content-div-1 " >
                        <div className="modal-header">
                            
                            <h1 className="modal-title">Preview</h1>
                            <button
                                type="button"
                                className="btn-close"
                                aria-label="Close"
                                onClick={handleClose}
                            ></button>
                        </div>
                        <div className="modal-body">
                        <span >Please review your content selection below and click <b>'Submit'</b> to confirm:</span>
                        <div className='row mt-3'>
                            {previewCondition.length > 0 && <div className='col-md-6'>
                                <h1 className='dashboard-graph-title mb-2'>Conditions</h1>
                            </div>}
                            {previewProcedures.length > 0 && <div className='col-md-6'>
                                <h1 className='dashboard-graph-title mb-2'>Procedures</h1>
                            </div>}
                        </div>
                            <div className="row">
                                {previewCondition.length > 0 && <div className='col-md-6' style={{maxHeight:"70vh", overflowX:"hidden", overflowY:"scroll"}}>
                                    <div className="body-tree-view">
                                        {previewCondition?.map((itemFirst, index) => (
                                            <>
                                            <details close key={index}>
                                                <summary className="d-flex align-items-center">
                                                    {itemFirst.title}
                                                </summary>
                                                {itemFirst.topics ? ( 
                                                    <>
                                                        {itemFirst.topics.map((itemSecond, index) => (
                                                            <details close>
                                                                <summary className="d-flex align-items-center">
                                                                    {itemSecond.title}
                                                                </summary>
                                                                <ul key={index}>
                                                                    {itemSecond.contents ? ( 
                                                                        <>
                                                                        {itemSecond.contents.map((itemThird, index) => (
                                                                            <>
                                                                                <li className="d-flex align-items-center"><span>{itemThird.title}</span></li>
                                                                            </>
                                                                        ))}
                                                                        </> 
                                                                        ) : ('') }
                                                                </ul>
                                                            </details>
                                                        ))}
                                                    </>
                                                ) : ("") }
                                            </details>
                                            </>
                                        )
                                        )}
                                    </div>
                                </div>}


                                {previewProcedures.length > 0 && <div className='col-md-6' style={{maxHeight:"70vh", overflowX:"hidden", overflowY:"scroll"}}>
                                    <div className="body-tree-view">
                                        {previewProcedures?.map((itemFirst, index) => (
                                            <>
                                            <details close key={index}>
                                                <summary className="d-flex align-items-center">
                                                    {itemFirst.title}
                                                </summary>
                                                {itemFirst.topics ? ( 
                                                    <>
                                                        {itemFirst.topics.map((itemSecond, index) => (
                                                            <details close>
                                                                <summary className="d-flex align-items-center">
                                                                    {itemSecond.title}
                                                                </summary>
                                                                <ul>
                                                                    {itemSecond.contents ? ( 
                                                                        <>
                                                                        {itemSecond.contents.map((itemThird, index) => (
                                                                            <>
                                                                                <li className="d-flex align-items-center"><span>{itemThird.title}</span></li>
                                                                            </>
                                                                        ))}
                                                                        </> 
                                                                        ) : ('') }
                                                                </ul>
                                                            </details>
                                                        ))}
                                                    </>
                                                ) : ("") }
                                            </details>
                                            </>
                                        )
                                        )}
                                    </div>
                                </div>}

                            </div>
                        </div>
                        <div className="modal-footer">
                        <div className="col-md-12 px-3 my-2 text-center">
                            <div className={`${errorMessageSave != "" ? "errorDiv" : "hide"}`} style={{ fontWeight: "400 !important" }}>{errorMessageSave}</div>
                                {showSaveButton ? 
                                    <button
                                        type="submit"
                                        className="btn btn-primary program-save-btn me-2"
                                        onClick={(e) => handleFinalSubmit()}
                                    >
                                        Submit
                                    </button>
                                    :
                                    <CustomFieldLevelLoader />
                                }
                            </div>
                        </div>
                    </div>
                    </div>
                </Modal>}
                


                
                <Modal dialogClassName="modal-xl modal-dialog-centered div-10p" style={{width:"100%", paddingRight:"0"}}  show={showModalAssign} onHide={handleAssigClose} id="showListDataTable" backdrop="static" keyboard={false}>
                    <div className="modal-backdrop modulepups">
                    <div className="modal-content modal-content-div-1">
                        <div className="modal-header">
                        <h1 className="modal-title">Assign</h1>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={handleAssigClose} // Changed from handleClose to handleAssigClose
                        ></button>
                        </div>
                        <div className="modal-body">
                        <span >Please review your content selection below and click <b>'Submit'</b> to confirm:</span>
                        <div className='row mt-3'>
                            <div className='col-md-6'>
                                <h1 className='dashboard-graph-title mb-2'>Conditions</h1>
                            </div>
                             <div className='col-md-6'>
                                <h1 className='dashboard-graph-title mb-2'>Procedures</h1>
                            </div>
                        </div>
                            <div className="row">
                               <div className='col-md-6' style={{maxHeight:"70vh", overflowX:"hidden", overflowY:"scroll"}}>
                                    <div className="body-tree-view">
                                            <>
                                            <details close>
                                                <summary className="d-flex align-items-center">
                                                    itemFirst.title
                                                </summary>
                                               
                                                            <details close>
                                                                <summary className="d-flex align-items-center">
                                                                    itemSecond.title
                                                                </summary>
                                                                <ul>
                                                                   <li className="d-flex align-items-center"><span>itemThird.title</span></li>
                                                                   <li className="d-flex align-items-center"><span>itemThird.title</span></li>
                                                                </ul>
                                                            </details>
                                            </details>
                                            </>
                                    </div>
                                </div>

                                <div className='col-md-6' style={{maxHeight:"70vh", overflowX:"hidden", overflowY:"scroll"}}>
                                    <div className="body-tree-view">
                                            <>
                                            <details close>
                                                <summary className="d-flex align-items-center">
                                                    itemFirst.title
                                                </summary>
                                                            <details close>
                                                                <summary className="d-flex align-items-center">
                                                                    itemSecond.title
                                                                </summary>
                                                                <ul>
                                                                   <li className="d-flex align-items-center"><span>itemThird.title</span></li>
                                                                   <li className="d-flex align-items-center"><span>itemThird.title</span></li>
                                                                </ul>
                                                            </details>
                                            </details>
                                            </>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                        <div className="col-md-12 px-3 my-2 text-center">
                            <button
                            type="submit"
                            className="btn btn-primary program-save-btn"
                            >
                            Submit
                            </button>
                        </div>
                        </div>
                    </div>
                    </div>
                </Modal>

                <Modal dialogClassName="modal-xl modal-dialog-centered div-10p" style={{width:"100%", paddingRight:"0"}}
                    show={showModalReview} onHide={handleReviewClose} id="showListDataTable" backdrop="static" keyboard={false}>
                    <div className="modal-backdrop modulepups">
                        <div className="modal-content modal-content-div-1">
                            <div className="modal-header">
                                <h1 className="modal-title">Review</h1>
                                <button type="button" className="btn-close" aria-label="Close" onClick={handleReviewClose} // Changed
                                    from handleClose to handleReviewClose></button>
                            </div>
                            <div className="modal-body">
                            <span >Please review your content selection below and click <b>'Submit'</b> to confirm:</span>
                            <div className='row mt-3'>
                            <div className='col-md-6'>
                                <h1 className='dashboard-graph-title mb-2'>Conditions</h1>
                            </div>
                             <div className='col-md-6'>
                                <h1 className='dashboard-graph-title mb-2'>Procedures</h1>
                            </div>
                        </div>
                            <div className="row">
                        <div className='col-md-6' style={{maxHeight:"70vh", overflowX:"hidden", overflowY:"scroll"}} >
                            {
                                showConditionList ? 
                                    conditionsData && (
                                        <TreeStructure
                                            contentData={conditionsData}
                                            onSelectionChange={(selectedItems) => handleSelectedItems('conditions', selectedItems)}
                                            clearSelectionsTrigger={clearSelectionsTrigger}
                                            selectedProcedureNew = {selectedProcedureNew}
                                            selectedTopicNew = {selectedTopicNew}
                                            selectedContentsNew = {selectedContentsNew}
                                        />
                                    )
                                :
                                (
                                    showConditionError ? 
                                        <>
                                            <div className={`${showConditionError != "" ? "errorDiv" : "hide"}`} style={{ fontWeight: "400 !important" }}>{showConditionError}</div>
                                        </>
                                    :
                                    !showConditionError && !errorMessage && <CustomFieldLevelLoader />
                                )
                            }
                        </div>
                        
                        <div className='col-md-6' style={{maxHeight:"70vh", overflowX:"hidden", overflowY:"scroll"}}>
                            {
                                showProceduresList ? 
                                    proceduresData && (
                                        <TreeStructure  
                                            contentData={proceduresData}
                                            onSelectionChange={(selectedItems) => handleSelectedItems('procedures', selectedItems)}
                                            clearSelectionsTrigger={clearSelectionsTrigger}
                                            selectedProcedureNew = {selectedProcedureTwo}
                                            selectedTopicNew = {selectedTopicTwo}
                                            selectedContentsNew = {selectedContentsTwo}
                                        />
                                    )
                                :
                                (
                                    showProceduresError ? 
                                        <>
                                            <div className={`${showProceduresError != "" ? "errorDiv" : "hide"}`} style={{ fontWeight: "400 !important" }}>{showProceduresError}</div>
                                        </>
                                    :
                                    !showProceduresError && !errorMessage &&  <CustomFieldLevelLoader />
                                )
                            }
                        </div>
                    </div>
                            </div>
                            <div className="modal-footer">
                                <div className="col-md-12 px-3 my-2 text-center">
                                    <button type="submit" className="btn btn-primary program-save-btn">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>


                <Modal dialogClassName="modal-xl modal-dialog-centered div-10p" style={{width:"100%", paddingRight:"0"}}
                    show={showModalPreviewTP} onHide={handlePreviewTPClose} id="showListDataTable" backdrop="static" keyboard={false}>
                    <div className="modal-backdrop modulepups">
                        <div className="modal-content modal-content-div-1">
                            <div className="modal-header">
                                <h1 className="modal-title">Preview</h1>
                                <button type="button" className="btn-close" aria-label="Close" onClick={handlePreviewTPClose} // Changed
                                    from handleClose to handlePreviewTPClose></button>
                            </div>
                            <div className="modal-body">
                            <span >Please review your content selection below and click <b>'Submit'</b> to confirm:</span>
                            <div className='row mt-3'>
                            <div className='col-md-6'>
                                <h1 className='dashboard-graph-title mb-2'>Conditions</h1>
                            </div>
                             <div className='col-md-6'>
                                <h1 className='dashboard-graph-title mb-2'>Procedures</h1>
                            </div>
                        </div>
                            <div className="row">
                        <div className='col-md-6' style={{maxHeight:"70vh", overflowX:"hidden", overflowY:"scroll"}} >
                            {
                                showConditionList ? 
                                    conditionsData && (
                                        <TreeStructure
                                            contentData={conditionsData}
                                            onSelectionChange={(selectedItems) => handleSelectedItems('conditions', selectedItems)}
                                            clearSelectionsTrigger={clearSelectionsTrigger}
                                            selectedProcedureNew = {selectedProcedureNew}
                                            selectedTopicNew = {selectedTopicNew}
                                            selectedContentsNew = {selectedContentsNew}
                                        />
                                    )
                                :
                                (
                                    showConditionError ? 
                                        <>
                                            <div className={`${showConditionError != "" ? "errorDiv" : "hide"}`} style={{ fontWeight: "400 !important" }}>{showConditionError}</div>
                                        </>
                                    :
                                    !showConditionError && !errorMessage && <CustomFieldLevelLoader />
                                )
                            }
                        </div>
                        
                        <div className='col-md-6' style={{maxHeight:"70vh", overflowX:"hidden", overflowY:"scroll"}}>
                            {
                                showProceduresList ? 
                                    proceduresData && (
                                        <TreeStructure  
                                            contentData={proceduresData}
                                            onSelectionChange={(selectedItems) => handleSelectedItems('procedures', selectedItems)}
                                            clearSelectionsTrigger={clearSelectionsTrigger}
                                            selectedProcedureNew = {selectedProcedureTwo}
                                            selectedTopicNew = {selectedTopicTwo}
                                            selectedContentsNew = {selectedContentsTwo}
                                        />
                                    )
                                :
                                (
                                    showProceduresError ? 
                                        <>
                                            <div className={`${showProceduresError != "" ? "errorDiv" : "hide"}`} style={{ fontWeight: "400 !important" }}>{showProceduresError}</div>
                                        </>
                                    :
                                    !showProceduresError && !errorMessage &&  <CustomFieldLevelLoader />
                                )
                            }
                        </div>
                    </div>
                            </div>
                            <div className="modal-footer">
                                <div className="col-md-12 px-3 my-2 text-center">
                                    <button type="submit" className="btn btn-primary program-save-btn">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
        </>
    )
}

export default EducationContent;