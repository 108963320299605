import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';

// Initialize exporting module
exporting(Highcharts);

const TaskCompletionRateBar = ({heading}) => {
  // Define your data

  let months,servicesBooked,servicesCompeted;

  if(heading == 'Task'){
    months = ['April', 'May', 'June', 'July'];
    servicesBooked = [370, 415, 435, 80];
    servicesCompeted = [335, 384, 394, 72];
  }else{
    months = ['April', 'May', 'June', 'July'];
    servicesBooked = [350, 395, 410, 50];
    servicesCompeted = [312, 360, 374, 55];
  }
  

  const yAxisJson = {
    title: {
      text: null
    }
  };

  const tooltipJson = {
    shared: true,
    valueSuffix: ' Calls'
  };

  const seriesData = [{
    name: heading + ' Due',
    data: servicesBooked,
    color: '#1f78b4'
  }, {
    name: heading + ' Completed',
    data: servicesCompeted,
    color: '#252954'
  }];

  const optionsCNP2 = {
    chart: {
      type: 'column',
      height: 260,
      spacing: [20, 0, 0, 0],
      options3d: {
        enabled: true,
        alpha: 0,
        beta: 10,
        depth: 50,
        viewDistance: 25
      }
    },
    title: {
      text: null,
      align: 'left',
      style: {
        fontSize: '20px',
        fontWeight: '600',
        color: '#333',
        fontFamily: "IBM Plex Sans, sans-serif"
      }
    },
    xAxis: {
      categories: months,
      gridLineWidth: 0
    },
    yAxis: yAxisJson,
    tooltip: tooltipJson,
    plotOptions: {
      column: {
        borderRadius: 5,
        pointPadding: 0.2,
        groupPadding: 0.1,
        stacking: null, // Use null for side-by-side columns
        dataLabels: {
          enabled: true,
          style: {
            textOutline: 'none'
          }
        }
      }
    },
    series: seriesData,
    credits: {
      enabled: false
    },
    exporting: {
          enabled: true,
          buttons: {
            contextButton: {
              enabled: false
            }
          }
        },
    legend: {
      enabled: true
    }
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={optionsCNP2}
      />
    </div>
  );
};

export default TaskCompletionRateBar;
