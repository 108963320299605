import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';

// Initialize exporting module
exporting(Highcharts);

const PriorityDistribution = (props) => {
  const { data } = props;

  const options = {
    chart: {
      type: "column",
      backgroundColor: 'transparent',
      height: 260,
      options3d: {
        enabled: true,
        alpha: 0,
        beta: 15,
        depth: 50,
        viewDistance: 0
      },
      marginTop: 34
    },
    title: {
      text: null
    },
    xAxis: {
      categories: ['Blocker', 'Major', 'Minor', 'Good To Have'],
      gridLineWidth: 0
    },
    yAxis: {
      title: {
        text: "Number of Observations"
      }
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
    },
    plotOptions: {
      column: {
        borderRadius: 5,
        pointPadding: 0.2,
        groupPadding: 0.1,
        dataLabels: {
          enabled: true,
          style: {
            textOutline: 'none'
          }
        }
      }
    },
    series: [{
      name: 'Priority',
      data: [
        {y: data?.blocker || 0, color: '#1F5B97'},
        {y: data?.major || 0, color: '#033B7E'}, 
        {y: data?.minor || 0, color: '#04A0AF'},
        {y: data?.good_to_have || 0, color: '#008FFB'}
      ]
    }],
    credits: {
      enabled: false
    },
    exporting: {
          enabled: true,
          buttons: {
            contextButton: {
              enabled: false
            }
          }
        },
    legend: {
      enabled: false
    }
  };

  return (
    <div>
      {(data?.blocker === 0 && data?.major === 0 && data?.minor === 0 && data?.good_to_have === 0) ? (
        <div className="emty-column">
          <h5 className="emty-title-graph">There are no data to display</h5>
        </div>
      ) : (
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
      )}
    </div>
  );
};

export default PriorityDistribution;
