import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from 'highcharts/highcharts-3d';
import exporting from 'highcharts/modules/exporting';
import { formatDate } from '../../utils/CommonLibrary';
import { agent_call_conversion_graph } from '../../../services/met/Service';

// Initialize exporting module
exporting(Highcharts);
highcharts3d(Highcharts);

const CallConversionDispositionBar = ({startDate,endDate,agentId,requestFrom}) => {

  const [loadingCount, setLoadingCount] = useState(0);
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    agentDataQualityGraph();    
}, [agentId,startDate,endDate]);

  const agentDataQualityGraph = async () => {
    try {
        let sdate = formatDate(startDate);
        let edate = formatDate(endDate);
        let response = await agent_call_conversion_graph(sdate,edate,agentId,requestFrom);
        if(response.data.code === 200){
            setGraphData(response.data.data);
            setLoadingCount(response.data.data.length)
        }
    } catch (error) {
        console.log(error);
    } 
}


  const pieData = graphData?.map(status => ({
    name: status.memberStatus,
    y: status.totalCount,
    color: status.color
  }));
  

  const pieOptionsMCI1 = {
    chart: {
      type: 'pie',
      height: 200,
      spacing: [0, 0, 0, 0],
      options3d: {
        enabled: true,
        alpha: 70,
        beta: 0
      },
    },
    title: {
      text: null,
      align: 'left',
      style: {
        fontSize: '20px',
        fontWeight: '600',
        color: '#333',
        fontFamily: "IBM Plex Sans, sans-serif",
        marginBottom: '10px'
      }
    },
    tooltip: {
      pointFormat: '{point.name}: <b>{point.percentage:.1f}%</b>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        depth: 30,
        size: 190,
        dataLabels: {
          enabled: true, // Enable data labels
          format: '{point.percentage:.1f}%',
          distance: 5, // Adjust label position
          alignTo: 'toPlotEdges' // Center the label inside the pie slice
        },
        showInLegend: true
      }
    },
    series: [{
      name: 'Service Status',
      data: pieData
    }],
    credits: {
      enabled: false
    },
    exporting: {
          enabled: true,
          buttons: {
            contextButton: {
              enabled: false
            }
          }
        },
    legend: {
      enabled: true
    }
  };

  return (
    <>
    {graphData.length > 0 ? (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={pieOptionsMCI1}
      />
    </div>
     ) : (

      <div className="emty-pie">
                                    <h5 className="emty-title-graph">There are no data to display</h5>
                                </div>
     )} 
     </>
  );
};

export default CallConversionDispositionBar;
