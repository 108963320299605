import React, { useState, useEffect, useRef } from 'react';
import { add_cn_call_disposition, fetch_cn_call_disposition_list } from '../../../services/medengage/Service';
import CNCallDispositionList from './CNCallDispositionList';
import { validateFormCNCallDispositionAdd } from '../../utils/validationUtils';

function CNCallDisposition() {

    const CNCallDispositionListRef = useRef();

    const [selectedData, setSelectedData] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => { setShowModal(false); }
    const [finalData, setFinalData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [key, setKey] = useState(1);

    const [formData, setFormData] = useState({
        callStatus: '',
        callStream: '',
    })

    const [errors, setErrors] = useState({
        callStatus: '',
        callStream: '',
    })

    /*useEffect(() => {
        fetchAllData();
    }, []);*/



    /*const fetchAllData = async () => {
        try {
            let resp = await fetch_cn_call_disposition_list();
            if (resp.data.code === 200) {
                console.log("resp.data", resp.data.data)
                setFinalData(resp.data.data);
                console.log("finalData", finalData);
            } else {
                setFinalData(resp.data.data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            //setIsLoading(false); // Will be called regardless of success or failure
        }
    }*/

    const handleChange = (event) => {
        setFormData((prevData) => ({ ...prevData, ['callStatus']: event.target.value }));
    };


    const addNewDisposition = async (e) => {
        e.preventDefault();
        setErrors('')

        const validationErrors = validateFormCNCallDispositionAdd(formData);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            add_cn_call_disposition(formData)
                .then((response) => {
                    if (response.data.code == 200) {
                        setShowModal(false);
                        //fetchAllData();
                        CNCallDispositionListRef.current.fetchData();
                    }
                });
        }
    }

    const onValueChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const setShowModalOpen = (e) => {
        setFormData('');
        setShowModal(true);

    }

    return (
        <>
            <div className="container-fluid">
                <div className=''>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <CNCallDispositionList ref={CNCallDispositionListRef} setShowModalOpen={setShowModalOpen} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {/*<section className='ContentPrograms'>
                <div className="container-fluid px-0 mb-3">
                    <div className="row align-items-center pb-3 sticky-top-bar">
                        <div className="col-md">
                            <h4 className="mb-0 content-main-title">CareNavigator Call Dispositions</h4>
                        </div>
                        <div className="col-md text-end">
                            <button onClick={setShowModalOpen} className="btn btn-primary add-new-program-btn">
                                <i className="bi bi-card-checklist me-2"></i> Add New Disposition
                            </button>
                        </div>
                    </div>
                    <div className='reports-programs mb-3'>
                        <div className="row align-items-center">
                            <div className="col-md-auto">
                                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/5jw18/wh4l7e7hk4.png" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
                            </div>
                            <div className="col-md">
                                <p className="mb-0">The CareNavigator disposition settings enable you to efficiently manage call disposition reasons, ensuring accurate data tracking and correction.</p>
                            </div>
                        </div>
                    </div>

                    <div className='content-programs mb-3'>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    <CNCallDispositionList ref={CNCallDispositionListRef} />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                
        </section >*/
            }

            {
                showModal &&
                <>
                <div className="modal fade show" id="addNewDispositionModal" tabIndex="-1" aria-hidden="false" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="main-title">Add New Call Disposition</h4>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={handleClose}
                                ></button>
                            </div>
                            <div class="modal-body pt-1 mb-2">
                                <form className='' onSubmit={addNewDisposition}>
                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor='Condition' class="form-label">Call Status </label>
                                            <span style={{ color: 'red' }}> *</span>
                                            <select class="form-select input-field-ui" aria-label="Default select example" value={formData['callStatus']} onChange={handleChange}>
                                                <option value="">Select</option>
                                                <option value="completed">Completed</option>
                                                <option value="membernoshow">Member No-Show</option>
                                                <option value="missed">Missed</option>
                                                <option value="cancelled">Cancelled</option>
                                            </select>
                                            {(errors['callStatus']) && <div className="text-danger">{errors['callStatus']}</div>}
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor='Condition' class="form-label">Stream </label>
                                            <span style={{ color: 'red' }}> *</span>
                                            <textarea
                                                className="form-control input-field-ui"
                                                placeholder="Call Stream"
                                                rows="3"
                                                name="callStream"
                                                onChange={(e) => onValueChange(e)} value={formData['callStream']}
                                            />
                                            {errors['callStream'] && <div className="text-danger">{errors['callStream']}</div>}
                                        </div>

                                        <div className="col-md-12 mt-2 text-center">
                                            <button
                                                type="submit"
                                                className="btn btn-primary program-save-btn"
                                            >
                                                Add Disposition
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show"></div>
                </>
            }
        </>
    );
}

export default CNCallDisposition