import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from "axios";
import './MemberJourney.css';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TimelineProfile from '../../../MemberProfile/TimelineProfile';
import '../../../MemberProfile/TimelineProfile.css'; // Import the CSS file
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Link } from 'react-router-dom';
import { CustomLoader, exportTableData } from '../../../utils/CommonLibrary';
import { fetchMember360Profile } from '../services/Service';
import { NotificationToast, showToast } from '../../../componenents/ContentCareNavigator/NotificationToast';
import { RenderStringWithBreaks } from '../../../utils/CommonLibrary';
import HealthLockerMP from '../../../MemberProfile/HealthLockerMP';
import { usePageContext } from 'react-pdf/dist/cjs/index.js';

function MemberJourney({ userId, showMemberJourneyModal, handleShowMemberJourneyModal, handleCloseMemberJourneyModal, memberBasicInfo }) {

  const origMemberBasicInfo = memberBasicInfo;
  const [eventsFound, setEventsFound] = useState(true);
  //const [memberBasicInfo, setMemberBasicInfo] = useState([]);
  const [events, setEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [memberPathways, setMemberPathways] = useState([]);
  const [showUserProfile, setShowUserProfile] = useState(true);
  const [loading, setLoading] = useState(false);
  const [distinctActions, setDistinctActions] = useState([]);
  const [filterText, setFilterText] = useState([]);
  const [serviceLogs, setServiceLogs] = useState([]);
  //CN Calls
  const [callDetails, setCallDetails] = useState(false);
  const [showCallDetailModal, setShowCallDetailModal] = useState(false);

  //Home Based Service 
  const [fileUrl, setFileUrl] = useState('');
  const [hbsRequestDetail, setHbsRequestDetail] = useState({});
  const [showMr, setShowMr] = useState(false);
  const [showLr, setShowLr] = useState(false);
  const [showHcr, setShowHcr] = useState(false);
  const [showHbv, setShowHbv] = useState(false);
  const [hbsModalHeader, setHbsModalHeader] = useState("");
  const [showHBSDetailModal, setShowHBSDetailModal] = useState(false);

  useEffect(() => {
    getMember360Profile();
  }, [userId])

  useEffect(() => {
    if (filterText.length === 0) {
      setEvents(allEvents);
    } else {
      setEvents(allEvents.filter(event => filterText.includes(event.action)));
    }
  }, [filterText, allEvents]);

  const getMember360Profile = async () => {
    setShowUserProfile(true);
    setLoading(true);
    const request = axios.CancelToken.source();
    let ct = {
      cancelToken: request.token
    }

    await fetchMember360Profile(origMemberBasicInfo.membershipNumber)
      .then((response) => {
        if (response.data.code === 200) {
          setShowUserProfile(true);
          setEvents(response.data.data.info.events);
          setAllEvents(response.data.data.info.events);

          if (response.data.data.info.events.length <= 0) {
            setEventsFound(false);
          } else {
            setEventsFound(true);
          }

          const da = [...new Set(response.data.data.info.events.map(event => event.action))].sort();
          setDistinctActions(da);

        } else {
          showToast(response.data.data.message, 'error', 'top-right');
        }
        setLoading(false);
      }).catch(function (error) {
        console.log(error);
      });
    return () => request.cancel();

  }

  const handleExport = () => {
    const profileData = [
      {
        'Name': memberBasicInfo.memberName,
        'Email': memberBasicInfo.email
      }
    ]
    const modifiedData = events.map(item => {
      return {
        'Date': item.readableDate,
        'Action': item.action,
        'Description': item.description
      };
    });
    const mergedData = [...profileData, ...modifiedData];

    exportTableData("MemberProfile_" + memberBasicInfo.membershipNumber + "_", modifiedData);
  }

  const handleTagClick = (item) => {
    setFilterText((prevFilterText) => {
      if (prevFilterText.includes(item)) {
        return prevFilterText.filter((i) => i !== item);
      } else {
        return [...prevFilterText, item];
      }
    });
  };

  const handleDetailsModal = async (keyword, itemDetails) => {
    setShowMr(false);
    setShowLr(false);
    setShowHcr(false);
    setShowHbv(false);

    if (keyword == "cncalls") {
      setCallDetails(itemDetails);
      setShowCallDetailModal(true);
    }

    if (keyword == "hbsmedications" || keyword == "hbslab" || keyword == "hbshomecare" || keyword == "hbshomebasedvaccines") {
      setHbsRequestDetail(itemDetails);
      if (keyword === "hbsmedications") {
        setHbsModalHeader("Medication Request");
        setShowMr(true);
      } else if (keyword === "hbslab") {
        setHbsModalHeader("Lab Request");
        setShowLr(true)
      } else if (keyword === "hbshomecare") {
        setHbsModalHeader("Health Care Request");
        setShowHcr(true)
      } else {
        setHbsModalHeader("Home Based Vaccine");
        setShowHbv(true)
      }
      setShowHBSDetailModal(true);
    }

  }

  return (
    <>
      {/* Uncomment Navbar if needed */}
      {/* <Navbar /> */}
      <NotificationToast />
      {
        loading === true ? <CustomLoader /> : ""
      }

      <Modal id="MemberJourney" show={showMemberJourneyModal} onHide={handleCloseMemberJourneyModal} size="xl" backdrop="static" keyboard={false} fullscreen={false}>
        <Modal.Header closeButton>
          <div className='row align-items-center' style={{ width: "98%" }}>
            <div className='col'>
              <h1 className='modal-title'>Member Journey</h1>
            </div>
          </div>

        </Modal.Header>
        <Modal.Body>
          <section className='ReportsPrograms'>
            <div className="container-fluid px-0 mb-3 ">



              <div id="content-wrapper" className="">
                <div className=" mb-3">
                  {
                    showUserProfile === true &&
                    <div className='row'>
                      {
                        distinctActions.length > 0 &&
                        <div className='row'>
                          <div className='col-md-12 mt-3'>
                            <div className="button-row">
                              <button className={`action-timelin-button ${filterText.length <= 0 ? "action-timeline-active" : ""}`} onClick={() => setFilterText([])}>All</button>
                              {
                                distinctActions.map((item, index) => {
                                  return <button key={index} className={`action-timelin-button ${filterText.includes(item) ? "action-timeline-active" : ""}`} onClick={() => handleTagClick(item)}>{item}</button>
                                })
                              }
                            </div>
                          </div>
                        </div>
                      }

                      <div className='col-md-12 my-3'>
                        <div className='comntent-MemberDeEnrollment profile-card-timeline mb-3'>
                          <div className="row">
                            <div className="col-md-12">
                              <div id="container-timeline">
                                <ol className="timeline-profile">
                                  {
                                    events.map((item, index) => {
                                      return <li className="call" >
                                        <time>
                                          <small>{item.readableDate}</small>
                                          <div className="big-profile">{item.action}</div>
                                        </time>
                                        <article>
                                          <p>
                                            {item.description}
                                            {
                                              (item.details && (item.keyword == "hbsmedications" || item.keyword == "hbslab" || item.keyword == "hbshomecare" || item.keyword == "hbshomebasedvaccines" || item.keyword == "cncalls")) &&
                                              <i style={{ marginLeft: '10px', marginTop: "2px", fontSize: "13px", cursor: 'pointer', color: '#82B63A' }} className="fa fa-info-circle" aria-hidden="true" onClick={() => {
                                                handleDetailsModal(item.keyword, item.details)
                                              }}></i>
                                            }

                                          </p>
                                        </article>
                                      </li>
                                    })
                                  }

                                </ol>
                              </div>

                              {
                                eventsFound === false &&
                                <center><span>There are no records to display</span></center>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>

      <Modal id="CallDetailsModal" show={showCallDetailModal} centered onHide={() => setShowCallDetailModal(false)} size="xl" backdrop="static" keyboard={false} fullscreen={false}>
        <Modal.Header closeButton>
          <h1 className='modal-title' style={{ width: "100%" }}>Call Details</h1>
        </Modal.Header>
        <Modal.Body>
          <>
            {
              <div>
                <Row>
                  <Col className='mb-3'>
                    <label style={{ fontWeight: 'bold' }}>Date:</label><br />
                    <span>{callDetails.date}</span>
                  </Col>
                  <Col className='mb-3'>
                    <label style={{ fontWeight: 'bold' }}>Call Scheduled By:</label><br />
                    <span>{callDetails.scheduledByName}</span>
                  </Col>
                  <Col className='mb-3'>
                    <label style={{ fontWeight: 'bold' }}>Call By:</label><br />
                    <span>{callDetails.cnName}</span>
                  </Col>
                </Row>
                <Row>
                  <Col className='mb-3'>
                    <label style={{ fontWeight: 'bold' }}>Call Type:</label><br />
                    <span>{callDetails.callType}</span>
                  </Col>
                  <Col className='mb-3'>
                    <label style={{ fontWeight: 'bold' }}>Call Sub-Type:</label><br />
                    <span>{callDetails.callSubType}</span>
                  </Col>
                  <Col className='mb-3'>
                    <label style={{ fontWeight: 'bold' }}>Call Status:</label><br />
                    <span>{callDetails.callCurrentStatus}</span>
                  </Col>
                </Row>
                <Row>
                  <Col className='mb-3'>
                    <label style={{ fontWeight: 'bold' }}>Streams:</label><br />
                    <span>{callDetails.streams}</span>
                  </Col>
                  <Col className='mb-3'>
                    <label style={{ fontWeight: 'bold' }}>Internal Notes:</label><br />
                    <span>{callDetails.internalNotes}</span>
                  </Col>
                  <Col className='mb-3'>
                    <label style={{ fontWeight: 'bold' }}>External Notes:</label><br />
                    <span>{callDetails.externalNotes}</span>
                  </Col>
                </Row>
                <Row>
                  <Col className='mb-3'>
                    <label style={{ fontWeight: 'bold' }}>Follow-up Date:</label><br />
                    <span>{callDetails.followupCallDateTime}</span>
                  </Col>
                  <Col className='mb-3'>
                    <label style={{ fontWeight: 'bold' }}>Follow-up Notes:</label><br />
                    <span>{callDetails.followupNotes}</span>
                  </Col>
                  <Col className='mb-3'>
                  </Col>
                </Row>
              </div>
            }
          </>
        </Modal.Body>
      </Modal>

      <Modal id="hbsh" show={showHBSDetailModal} centered onHide={() => setShowHBSDetailModal(false)} size="xl" backdrop="static" keyboard={false} fullscreen={false}>
        <Modal.Header closeButton>
          <h1 className='modal-title' style={{ width: "100%" }}>{hbsModalHeader}</h1>
        </Modal.Header>
        <Modal.Body>
          <>
            {

              showMr === true &&
              <div>
                <Row>
                  <Col>
                    <label>Membership</label><br />
                    <span>{hbsRequestDetail.membershipNo}</span>
                  </Col>
                  <Col>
                    <label>Name</label><br />
                    <span>{hbsRequestDetail.name}</span>
                  </Col>
                  <Col>
                    <label>Mobile</label><br />
                    <span>{hbsRequestDetail.mobileNumber}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Partner Clinic</label><br />
                    <span>{hbsRequestDetail.partnerName}</span>
                  </Col>
                  <Col>
                    <label>Pharmacy</label><br />
                    <span>{hbsRequestDetail.pharmacyName}</span>
                  </Col>
                  <Col>
                    <label>Delivery/Refill</label><br />
                    <span>{hbsRequestDetail.deliveryRefill}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>City</label><br />
                    <span>{hbsRequestDetail.city}</span>
                  </Col>
                  <Col>
                    <label>District</label><br />
                    <span>{hbsRequestDetail.district}</span>
                  </Col>
                  <Col>
                    <label>Deductible</label><br />
                    <span>{hbsRequestDetail.deductible}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Remarks</label><br />
                    <span>{hbsRequestDetail.remarks}</span>
                  </Col>
                  <Col>
                    <label>Medications</label><br />
                    <span><RenderStringWithBreaks inputString={hbsRequestDetail.medications} /></span>
                  </Col>
                  <Col>
                    <label>Status</label><br />
                    <span>{hbsRequestDetail.status}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Completion Date</label><br />
                    <span>{hbsRequestDetail.completionDate}</span>
                  </Col>
                </Row>
              </div>
            }

            {
              showLr === true &&
              <div>
                <Row>
                  <Col>
                    <label>Membership</label><br />
                    <span>{hbsRequestDetail.membershipNo}</span>
                  </Col>
                  <Col>
                    <label>Name</label><br />
                    <span>{hbsRequestDetail.name}</span>
                  </Col>
                  <Col>
                    <label>Mobile</label><br />
                    <span>{hbsRequestDetail.mobileNumber}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Partner Clinic</label><br />
                    <span>{hbsRequestDetail.partnerName}</span>
                  </Col>
                  <Col>
                    <label>Lab Provider</label><br />
                    <span>{hbsRequestDetail.labName}</span>
                  </Col>
                  <Col>
                    <label>Approval Number</label><br />
                    <span>{hbsRequestDetail.approvalNumber}</span>
                  </Col>

                </Row>
                <Row>
                  <Col>
                    <label>City</label><br />
                    <span>{hbsRequestDetail.city}</span>
                  </Col>
                  <Col>
                    <label>District</label><br />
                    <span>{hbsRequestDetail.district}</span>
                  </Col>
                  <Col>
                    <label>Nationality</label><br />
                    <span>{hbsRequestDetail.nationality}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Deductible</label><br />
                    <span>{hbsRequestDetail.deductible}</span>
                  </Col>
                  <Col>
                    <label>Remarks</label><br />
                    <span>{hbsRequestDetail.remarks}</span>
                  </Col>
                  <Col>
                    <label>Requested Lab Tests</label><br />
                    <span>{hbsRequestDetail.requestedLabTest}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Status</label><br />
                    <span>{hbsRequestDetail.status}</span>
                  </Col>
                  {hbsRequestDetail.serviceDate && <Col>
                    <label>Appointment Date</label><br />
                    <span>{hbsRequestDetail.serviceDate}</span>
                  </Col>}
                  <Col>
                    <label>Completion Date</label><br />
                    <span>{hbsRequestDetail.completionDate}</span>
                  </Col>
                  {fileUrl.length > 0 &&
                    <Col>
                      <label>Download Report</label><br />
                      <div className='DropDownAction'>
                        <div className="btn-group dropend">
                          <button type="button" className="btn btn-light dropdown-toggle action-dropdown-btn" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-download"></i>
                          </button>
                          <ul className="dropdown-menu">
                            {
                              fileUrl.map((item, index) => {
                                return <>
                                  <li className='list-class' key={index}><Link className="dropdown-item" target="_blank" to={item.link}>{item.name}</Link></li>
                                </>
                              })
                            }
                          </ul>
                        </div>
                      </div>
                    </Col>
                  }
                </Row>
              </div>
            }

            {
              showHcr === true &&
              <div>
                <Row>
                  <Col>
                    <label>Membership</label><br />
                    <span>{hbsRequestDetail.membershipNo}</span>
                  </Col>
                  <Col>
                    <label>Name</label><br />
                    <span>{hbsRequestDetail.name}</span>
                  </Col>
                  <Col>
                    <label>Mobile</label><br />
                    <span>{hbsRequestDetail.mobileNumber}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Approval Number</label><br />
                    <span>{hbsRequestDetail.approvalNumber}</span>
                  </Col>

                  <Col>
                    <label>Partner Clinic</label><br />
                    <span>{hbsRequestDetail.partnerClinic}</span>
                  </Col>
                  <Col>
                    <label>Home Health Care Provider</label><br />
                    <span>{hbsRequestDetail.providerName}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>City</label><br />
                    <span>{hbsRequestDetail.city}</span>
                  </Col>
                  <Col>
                    <label>District</label><br />
                    <span>{hbsRequestDetail.district}</span>
                  </Col>
                  <Col>
                    <label>Nationality</label><br />
                    <span>{hbsRequestDetail.nationality}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Deductible</label><br />
                    <span>{hbsRequestDetail.deductible}</span>
                  </Col>
                  <Col>
                    <label>Home Care Service</label><br />
                    <span>{hbsRequestDetail.requestedHomeHealth}</span>
                  </Col>
                  <Col>
                    <label>Remarks</label><br />
                    <span>{hbsRequestDetail.remarks}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Status</label><br />
                    <span>{hbsRequestDetail.status}</span>
                  </Col>
                  {hbsRequestDetail.serviceDate && <Col>
                    <label>Appointment Date</label><br />
                    <span>{hbsRequestDetail.serviceDate}</span>
                  </Col>}
                  <Col>
                    <label>Completion Date</label><br />
                    <span>{hbsRequestDetail.completionDate}</span>
                  </Col>
                  {fileUrl.length > 0 &&
                    <Col>
                      <label>Download Report</label><br />
                      <div className='DropDownAction'>
                        <div className="btn-group dropend">
                          <button type="button" className="btn btn-light dropdown-toggle action-dropdown-btn" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-download"></i>
                          </button>
                          <ul className="dropdown-menu">
                            {
                              fileUrl.map((item, index) => {
                                return <>
                                  <li className='list-class' key={index}><Link className="dropdown-item" target="_blank" to={item.link}>{item.name}</Link></li>
                                </>
                              })
                            }
                          </ul>
                        </div>
                      </div>
                    </Col>
                  }
                </Row>
              </div>
            }

            {
              showHbv === true &&
              <div>
                <Row>
                  <Col>
                    <label>Child Membership</label><br />
                    <span>{hbsRequestDetail.membershipNo}</span>
                  </Col>
                  <Col>
                    <label>Child Name</label><br />
                    <span>{hbsRequestDetail.name}</span>
                  </Col>
                  <Col>
                    <label>Mobile</label><br />
                    <span>{hbsRequestDetail.mobileNumber}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>City</label><br />
                    <span>{hbsRequestDetail.city}</span>
                  </Col>
                  <Col>
                    <label>District</label><br />
                    <span>{hbsRequestDetail.district}</span>
                  </Col>
                  <Col>
                    <label>Nationality</label><br />
                    <span>{hbsRequestDetail.nationality}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Vaccine</label><br />
                    <span>{hbsRequestDetail.vaccine}</span>
                  </Col>

                  <Col>
                    <label>Partner Clinic</label><br />
                    <span>{hbsRequestDetail.partnerClinic}</span>
                  </Col>
                  <Col>
                    <label>Home Health Care Service</label><br />
                    <span>{hbsRequestDetail.requestedHomeHealth}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Deductible</label><br />
                    <span>{hbsRequestDetail.deductible}</span>
                  </Col>
                  <Col>
                    <label>Remarks</label><br />
                    <span>{hbsRequestDetail.remarks}</span>
                  </Col>
                  <Col>
                    <label>Status</label><br />
                    <span>{hbsRequestDetail.status}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Completion Date</label><br />
                    <span>{hbsRequestDetail.completionDate}</span>
                  </Col>
                </Row>
              </div>
            }

            {serviceLogs.length > 0 && <div className='table-responsive mt-2' style={{ maxHeight: "380px", overflowX: "hidden" }}>
              <table className="table table-striped table-bordered mb-0">
                <thead style={{ position: "sticky", top: "0" }}>
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    serviceLogs?.map((item, index) => {
                      return <>
                        <tr style={{ verticalAlign: 'middle' }}>
                          <td className='bg-td-green'>{item.createdTime}</td>
                          <td className='bg-td-green'>{item.action}</td>
                        </tr>
                      </>
                    })
                  }

                </tbody>
              </table>
            </div>}
          </>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MemberJourney;
