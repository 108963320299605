import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import BackendDrivenDataTable from '../../cn/components/common/BackendDrivenDataTable';
import { fetch_cn_call_disposition_info, edit_cn_call_disposition, fetch_cn_call_disposition_list, remove_cn_call_disposition } from '../../../services/medengage/Service';
import { validateFormCNCallDispositionAdd } from '../../utils/validationUtils';
import { CustomLoader } from '../../utils/CommonLibrary';

const CNCallDispositionList = forwardRef((props, ref) => {

    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState("callStatus");
    const [sortDirection, setSortDirection] = useState('desc');

    const [isNewLoading, setIsNewLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [selectedService, setSelectedService] = useState('All');
    const [passData, setPassData] = useState([]);
    const [selectedData, setSelectedData] = useState('');
    const [isLoading, setIsLoading] = useState('');
    const [editId, setEditId] = useState('');
    const [deleteId, setDeleteId] = useState('');
    const [dispositionDataList, setDispositionDataList] = useState([]);
    const [editShowModal, setEditShowModal] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState('');
    const handleClose = () => { setEditShowModal(false); }
    const [deleteShowModal, setDeleteShowModal] = useState(false);
    const handleDeleteClose = () => { setDeleteShowModal(false); }
    const [formData, setFormData] = useState({
        callStatus: '',
        callStream: '',
    })

    const [errors, setErrors] = useState({
        callStatus: '',
        callStream: '',
    })


    useEffect(() => {
        fetchData();
    }, [page, perPage, sortColumn, sortDirection, searchQuery]);


    useImperativeHandle(ref, () => ({
        fetchData
    }));

    const fetchData = async () => {
        //setIsLoading(true);
        try {
            let resp = await fetch_cn_call_disposition_list(searchQuery, page, perPage, sortColumn, sortDirection);
            if (resp.data.code == 200) {
                setPassData(resp.data.data.dispositions);
                setTotalRows(resp.data.data.totalRows);
            }
        } catch (error) {
            console.error(error);
        } finally {
            //setIsLoading(false); // Will be called regardless of success or failure
        }

    }

    const columns = [
        {
            name: 'Call Status',
            selector: row => row.callStatus,
            sortable: true,
        },
        {
            name: 'Call Stream',
            selector: row => row.callStream,
            sortable: true,

        },
        {
            name: 'Action',
            cell: row => (
                <div><button onClick={() => setShowEditModalOpen(row.id)} class="btn btn-Edit"><i className="bi bi-pencil"></i></button> | <button onClick={() => setShowDeleteModalOpen(row.id)} class="btn btn-Cancel"><i className="bi bi-trash"></i></button></div>
            ),
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedCategory('All');
    };

    const setShowEditModalOpen = async (id) => {
        setEditId(id);
        try {
            let resp = await fetch_cn_call_disposition_info(id);
            console.log("edit form", resp.data.data[0]);
            if (resp.data.code == 200) {
                setFormData(resp.data.data[0]);
            } else {
                setFormData(resp.data.data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            //setIsLoading(false); // Will be called regardless of success or failure
        }
        setEditShowModal(true);

    }
    const setShowDeleteModalOpen = async (id) => {
        setDeleteId(id);
        setDeleteShowModal(true);
    };
    const submitDeleteDisposition = async (deleteId) => {

        try {
            let resp = await remove_cn_call_disposition(deleteId);
            if (resp.data.code == 200) {
                setDeleteMessage(resp.data.data.message);
                setDeleteShowModal(false);
                fetchData();
            }
        } catch (error) {
            console.error(error);
        } finally {
            //setIsLoading(false); // Will be called regardless of success or failure
        }


    }

    const handleChange = (event) => {
        setFormData((prevData) => ({ ...prevData, ['callStatus']: event.target.value }));
    };


    const editNewDisposition = async (e) => {
        e.preventDefault();
        setErrors('')

        const validationErrors = validateFormCNCallDispositionAdd(formData);

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            const combinedData = {
                ...formData,
                id: editId
            };
            edit_cn_call_disposition(combinedData)
                .then((response) => {
                    if (response.data.code == 200) {
                        setEditShowModal(false);
                        fetchData();

                    }
                });
        }
    }

    const onValueChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }


    return (
        <>
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md-4 d-flex px-1'>
                        <div className="form-group" style={{ width: "100%" }}>
                            <input
                                type="text"
                                placeholder="Search By Call Status or Stream"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                            />
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md-4 px-1'>
                        <button onClick={handleClear} className='btn btn-primary clear-search-btn'>Clear</button>
                    </div>
                    <div className='col-md-4 px-1' style={{ textAlign: 'right' }}>
                        <button onClick={props.setShowModalOpen} className="btn btn-primary add-new-program-btn">
                            <i className="bi bi-card-checklist me-2"></i> Add New Disposition
                        </button>
                    </div>

                </div>
            </div>

            {
                isNewLoading ? <CustomLoader /> :
                    <BackendDrivenDataTable
                        data={passData}
                        loading={isNewLoading}
                        totalRows={totalRows}
                        page={page}
                        perPage={perPage}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                        filterText={searchQuery}
                        columns={columns}
                        fetchTableData={fetchData}
                        setPage={setPage}
                        setSortColumn={setSortColumn}
                        setSortDirection={setSortDirection}
                        setPerPage={setPerPage}
                    />
            }

            {editShowModal &&
                <>
                <div className="modal fade show" id="addNewServiceModal" tabIndex="-1" aria-hidden="false" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="main-title">Edit Call Disposition</h4>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={handleClose}
                                ></button>
                            </div>
                            <div class="modal-body pt-1 mb-2">
                                <form className='' onSubmit={editNewDisposition}>
                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor='Condition' class="form-label">Call Status</label>
                                            <span style={{ color: 'red' }}> *</span>
                                            <select class="form-select input-field-ui" aria-label="Default select example" value={formData['callStatus']} onChange={handleChange}>
                                                <option value="">Select</option>
                                                <option value="completed">Completed</option>
                                                <option value="membernoshow">Member No-Show</option>
                                                <option value="missed">Missed</option>
                                                <option value="cancelled">Cancelled</option>
                                            </select>
                                            {(errors['callStatus']) && <div className="text-danger">{errors['callStatus']}</div>}
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor='Condition' class="form-label">Stream</label>
                                            <span style={{ color: 'red' }}> *</span>
                                            <textarea
                                                className="form-control input-field-ui"
                                                placeholder="Reason"
                                                rows="3"
                                                name="callStream"
                                                onChange={(e) => onValueChange(e)} value={formData['callStream']}
                                            />
                                            {errors['callStream'] && <div className="text-danger">{errors['callStream']}</div>}
                                        </div>

                                        <div className="col-md-12 mt-2 text-center">
                                            <button
                                                type="submit"
                                                className="btn btn-primary program-save-btn"
                                            >
                                                Save Disposition
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>

                </div>
                <div className="modal-backdrop fade show"></div>
                </>
                }



            {deleteShowModal && (
                <div className="modal fade show" id="deleteConfirmationModal" tabIndex="-1" aria-hidden="false" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h5 className="modal-title fw-semibold">Delete Confirmation</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={handleDeleteClose}
                                    aria-label="Close"
                                ></button>
                            </div>

                            {/* Modal Body */}
                            <div className="modal-body py-4">
                                <p className="text-center mb-0">
                                    Are you sure you want to delete this call disposition?
                                </p>
                            </div>

                            {/* Modal Footer */}
                            <div className="tw-flex tw-justify-center tw-py-4">
                                {/* <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={handleDeleteClose}
                                >
                                    Cancel
                                </button> */}
                                <button
                                    type="button"
                                    className="btn btn-primary program-save-btn"
                                    onClick={() => submitDeleteDisposition(deleteId)}
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Modal Backdrop */}
                    <div className="modal-backdrop fade show"></div>
                </div>
            )}
        </>


    );
});

export default CNCallDispositionList