import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { fetch_audio_play_list, fetch_member_communication_data, fetch_member_communication_stats } from '../../../services/met/Service';
import { LoaderLoader, exportExcelTableData } from '../../utils/CommonLibrary';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { formatDate } from '../utils/CommonLibrary';
import ServerSideSortableDataTable from "../../cn/components/Tables/ServerSideSortableDataTable";
import CallStatusDistribution from './Graphs/PieChart';
import { toast} from "react-toastify";
import { NotificationToast } from '../../componenents/ContentCareNavigator/NotificationToast';


function MemberCommunicationsTable({ startDate, endDate }) {
    const [searchQuery, setSearchQuery] = useState('');
    const [callTypeFilter, setCallTypeFilter] = useState('');
    const [selectedChannel, setSelectedChannel] = useState('All');
    const [communicationData, setCommunicationData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [recordUrl, setRecordUrl] = useState('');
    const [agentCallName, setAgentCallName] = useState('');
    const [memberCallName, setMemberCallName] = useState('');
    const [randomKey, setRandomKey] = useState(0);
    const [callStatsDataPoints, setCallStatsDataPoints] = useState({
        totalCalls: 0,
        averageRecordingDUration: 0
    }); 

    const [isInitialRender, setIsInitialRender] = useState(true);


    const [communicationDistribution, setCommunicationDistribution] = useState({}); 
    const [memberResponseDistribution, setMemberResponseDistribution] = useState({}); 

    const statusOptions = {
        All: [
            { value: 'busy', label: 'Busy' },
            { value: 'completed', label: 'Completed' },
            { value: 'canceled', label: 'Cancelled' },
            { value: 'no-answer', label: 'No-Answer' },
            { value: 'failed', label: 'Failed' },
            { value: 'delivered', label: 'Delivered' },
            { value: 'undelivered', label: 'Undelivered' },
        ],
        Call: [
            { value: 'busy', label: 'Busy' },
            { value: 'completed', label: 'Completed' },
            { value: 'canceled', label: 'Cancelled' },
            { value: 'no-answer', label: 'No-Answer' },
            { value: 'failed', label: 'Failed' },

        ],
        Whatsapp: [
            { value: 'delivered', label: 'Delivered' },
            { value: 'undelivered', label: 'Undelivered' }
        ],
        SMS: [
            { value: 'delivered', label: 'delivered' },
            { value: 'undelivered', label: 'Undelivered' }
        ],
        Email: [
            { value: 'delivered', label: 'Delivered' },
            { value: 'undelivered', label: 'Undelivered' }
        ],
        'Push Notification': [
            { value: 'delivered', label: 'Delivered' },
            { value: 'undelivered', label: 'Undelivered' }
        ]
    };

    const [paginationConfig, setPaginationConfig] = useState({
        perPage: 10,
        currentPage: 1,
        totalRecords: 0,
        totalPages: 0
    });


    const [sortConfig, setSortConfig] = useState({
        field: null,
        direction: "asc"
    });

    const audioRef = useRef(null);

    useEffect(() => {
        let timer;
        if (searchQuery) {
            timer = setTimeout(() => {
                setTableLoading(true);
                fetchMemberCommunicationData();
                fetchCommumnicationDetailsStats();
            }, 400);
        } else {
            fetchMemberCommunicationData();
            fetchCommumnicationDetailsStats();
            setIsInitialRender(false); 
        }
        return () => clearTimeout(timer);
    }, [startDate, endDate, callTypeFilter, searchQuery]);

    useEffect(() => {
        let timer;

        if(!isInitialRender){
            fetchMemberCommunicationData();
        }
        return () => clearTimeout(timer);
    },  [paginationConfig.currentPage, paginationConfig.perPage, sortConfig.field, sortConfig.direction]); 

    useEffect(() => {
        if (audioRef.current && recordUrl) {
            audioRef.current.load();
        }
    }, [recordUrl])


    const handleStop = () => {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setRecordUrl('');
    };

    const fetchMemberCommunicationData = async (download="N") => {
        try {
            setTableLoading(true);
            let sdate = formatDate(startDate);
            let edate = formatDate(endDate);
            if(download == "Y") {
                setIsLoading(true); 
            }
            let response = await fetch_member_communication_data({ startDate: sdate, endDate: edate, field: sortConfig.field, direction: sortConfig.direction, perPage: paginationConfig.perPage, currentPage: paginationConfig.currentPage, searchQuery, callTypeFilter, download: download });
            if(download == "N") {
                setCommunicationData(response.data.data);
                setPaginationConfig({
                    ...paginationConfig,
                    totalRecords: response.data.paginationInfo?.totalEntries,
                    totalPages: response.data?.paginationInfo?.totalPages
                });                
            }else { 
                if (response.data.code === 200) {
                    toast.success("Report Processing", {
                        position: "top-right",
                    });
                } else {
                    toast.error("Something went wrong", {
                        position: "top-right",
                    });
                }
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setTableLoading(false);
            setIsLoading(false); 
        }
    };  

    const handlePlay = (e, itemName) => {
        let allAudios = document.querySelectorAll('.call_recording_audio');

        // pause other audio's except current one 
        Array.from(allAudios).forEach((item) => {
            let name = item.getAttribute('name');
            if (name !== itemName) {
                item.pause();
            }
        });
    }

    const playAudioRecording = async (callId) => {
        try {

            let response = await fetch_audio_play_list(callId);

            setRecordUrl(response.data.data.recordingUrl);
            setRandomKey(response.data.data.recordingUrl);
            setAgentCallName(response.data.data.AgentName);
            setMemberCallName(response.data.data.memberName);

        } catch (error) {
            console.error('Error fetching audio data:', error);
        }
    };


    const renderMETP1Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Unique identifier for the member.
        </Tooltip>
    );
    const renderMETP12Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Main Membership No for the member.
        </Tooltip>
    );

    const renderMETP2Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Name of the member.
        </Tooltip>
    );

    const renderMETP3Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Name of the Agent.
        </Tooltip>
    );


    const renderMETP4Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            The method used for communication
        </Tooltip>
    );

    const renderMETP5Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            The date and time when the communication was sent or made.
        </Tooltip>
    );


    const renderMETP6Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            The method used for communication status
        </Tooltip>
    );

    const renderMETP7Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Click to listen to the recorded communication message.
        </Tooltip>
    );


    const optionSelectedChannel = (e) => {
        setSelectedChannel(e);
    }


    // const columns = [
    //     {
    //         name: (
    //             <OverlayTrigger placement="top" overlay={renderMETP1Tooltip}>
    //                 <span>Member ID</span>
    //             </OverlayTrigger>
    //         ),
    //         selector: row => row.membershipNo,
    //         sortable: true,
    //     },
    //     {
    //         name: (
    //             <OverlayTrigger placement="top" overlay={renderMETP12Tooltip}>
    //                 <span>Main Membership No</span>
    //             </OverlayTrigger>
    //         ),
    //         selector: row => row.mainMembershipNo,
    //         sortable: true,
    //     },
    //     {
    //         name: (
    //             <OverlayTrigger placement="top" overlay={renderMETP2Tooltip}>
    //                 <span>Member Name</span>
    //             </OverlayTrigger>
    //         ),
    //         selector: row => row.memberName,
    //         sortable: true,
    //     },
    //     {

    //         name: (
    //             <OverlayTrigger placement="top" overlay={renderMETP3Tooltip}>
    //                 <span>Agent Name</span>
    //             </OverlayTrigger>
    //         ),
    //         selector: row => row.agentName,
    //         sortable: true,
    //     },

    //     {
    //         name: (
    //             <OverlayTrigger placement="top" overlay={renderMETP4Tooltip}>
    //                 <span>Communication<br />Channel</span>
    //             </OverlayTrigger>
    //         ),
    //         selector: row => row.communicationType,
    //         sortable: true,
    //         // width:"100px",
    //     },

    //     {
    //         name: (
    //             <OverlayTrigger placement="top" overlay={renderMETP5Tooltip}>
    //                 <span>Communication Date</span>
    //             </OverlayTrigger>
    //         ),
    //         selector: row => row.placedCallDate,
    //         sortable: true,
    //     },

    //     {
    //         name: (
    //             <OverlayTrigger placement="top" overlay={renderMETP6Tooltip}>
    //                 <span>Communication <br />Status</span>
    //             </OverlayTrigger>
    //         ),
    //         selector: row => row.status,
    //         sortable: true,
    //         // cell: row => (
    //         //     <span style={{ color: row.status === 'Delivered' ? '#01CD88' : '#FF0000' }}>
    //         //         {row.status}
    //         //     </span>
    //         // ),
    //     },
    //     {
    //         name: (
    //             <OverlayTrigger placement="top" overlay={renderMETP6Tooltip}>
    //                 <span>Communication Playback</span>
    //             </OverlayTrigger>
    //         ),
    //         selector: row => row.recordingUrl,
    //         sortable: true,
    //         //  cell:row=><CommunicationAudioPlayer recordingUrl={row.recordingUrl} />
    //         cell: row => (
    //             row.recordingDuration != "" ?
    //                 <a data-bs-toggle="modal" data-bs-target="#audioPopupModal" style={{ cursor: "pointer" }} onClick={(e) => playAudioRecording(row.communicationId)}><i style={{ fontSize: '30px' }} class="bi bi-play-circle-fill"></i></a>
    //                 : "-"
    //         ),
    //     },
    //     {

    //         name: (
    //             <OverlayTrigger placement="top" overlay={renderMETP7Tooltip}>
    //                 <span>Communication <br />Duration</span>
    //             </OverlayTrigger>
    //         ),

    //         selector: row => row.recordingDuration,
    //         ignoreRowClick: true,
    //         allowOverflow: true,
    //         button: true,
    //         width: "125px",

    //         cell: row => (
    //             <span>
    //                 {/* <a data-bs-toggle="modal" data-bs-target="#audioPopupModal" style={{cursor: "pointer"}} onClick={(e)=>playAudioRecording(row.communicationId)}><span style={{color:row.color}}>{row.recordingUrl}</span> <br/>{row.recordingDuration}</a> */}
    //                 {row.recordingDuration}
    //             </span>
    //         ),
    //     },


    //     {
    //         name: (
    //             <OverlayTrigger placement="top" overlay={renderMETP6Tooltip}>
    //                 <span>Sentimental Analysis</span>
    //             </OverlayTrigger>
    //         ),
    //         selector: row => row.recordingUrl,
    //         sortable: true,
    //         //  cell:row=><CommunicationAudioPlayer recordingUrl={row.recordingUrl} />
    //         cell: row => (
    //             <span>
    //                 {row.sentimentAnalysis || "-"}
    //             </span>
    //         ),
    //     },

    // ];


    const columns = [

        {

            name: (
                <OverlayTrigger placement="top" overlay={renderMETP3Tooltip}>
                    <span>Agent Name</span>
                </OverlayTrigger>
            ),
            selector: 'agentName',
            sortable: true,
        },

        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP1Tooltip}>
                    <span>Member ID</span>
                </OverlayTrigger>
            ),
            selector: 'membershipNo',
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP12Tooltip}>
                    <span>Main Membership No</span>
                </OverlayTrigger>
            ),
            selector: 'mainMembershipNo',
            sortable: true,
        },

        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP2Tooltip}>
                    <span>Member Name</span>
                </OverlayTrigger>
            ),
            selector: 'memberName',
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP5Tooltip}>
                    <span>Call Date</span>
                </OverlayTrigger>
            ),
            selector: 'placedCallDate',
            sortable: true,
        },

        // {
        //     name: (
        //         <OverlayTrigger placement="top" overlay={renderMETP6Tooltip}>
        //             <span>Call <br />Status</span>
        //         </OverlayTrigger>
        //     ),
        //     selector: 'status',
        //     sortable: true,
        // },

        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP6Tooltip}>
                    <span>Member Response</span>
                </OverlayTrigger>
            ),
            selector: 'enrollStatus',
            sortable: true,
        },

        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP6Tooltip}>
                    <span>Communication Playback</span>
                </OverlayTrigger>
            ),

            sortable: false,
            cell: row => (
                (row.recordingUrl != "" ) ?
                    <a data-bs-toggle="modal" data-bs-target="#audioPopupModal" style={{ cursor: "pointer" }} onClick={(e) => playAudioRecording(row.communicationId)}><i style={{ fontSize: '30px' }} class="bi bi-play-circle-fill"></i></a>
                    : "-"
            ),
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP7Tooltip}>
                    <span>Communication Duration</span>
                </OverlayTrigger>
            ),
            sortable: true,
            selector: 'recordingDuration',
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: "125px",

            cell: row => (
                <span>
                    <a data-bs-toggle="modal" data-bs-target="#audioPopupModal" style={{cursor: "pointer"}} onClick={(e)=>playAudioRecording(row.communicationId)}><span style={{color:row.color}}>{row.recordingUrl}</span> <br/>{row.recordingDuration}</a>
                    {row.recordingDuration}
                </span>
            ),
        },


        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP6Tooltip}>
                    <span>Sentimental Analysis</span>
                </OverlayTrigger>
            ),
            selector: 'sentimentAnalysis',
            sortable: false,    
        },

    ];

    function setPerPage(value) {
        setPaginationConfig({
            ...paginationConfig,
            currentPage: 1,
            perPage: value
        })
    }



    const updateSortConfig = (field, direction, isSortable) => {
        if (!isSortable) return;
        setSortConfig({
            field: field,
            direction: direction
    });
    }

    const data = communicationData;

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedChannel('All');
        
        setSortConfig({
            field: null,
            direction: null
        });

        setCallTypeFilter("");
    };


    const handleExport = (downloadType) => {
        if (downloadType === 'excel') {
            const modifiedData = data.map(item => {
                return {
                    'Member ID': item.membershipNo,
                    'Main Membership No': item.mainMembershipNo,
                    'Member Name': item.memberName,
                    'Agent Name': item.agentName,
                    'Communication Channel': item.communicationType,
                    'Communication Date': item.placedCallDate,
                    'Communication Status': item.status,
                };
            });
            modifiedData.forEach(function (v) {
                delete v.communicationId;
            });
            exportExcelTableData("member_communication", modifiedData);
        }
    };


    async function fetchCommumnicationDetailsStats() {
        let response;
            try {
            response = await fetch_member_communication_stats({ startDate: formatDate(startDate), endDate: formatDate(endDate), searchQuery, callTypeFilter });
            if (response.data?.code === 200) {
                setCallStatsDataPoints({
                    totalCalls: response.data.data?.communicationStats.totalCallCount,
                    averageRecordingDUration: response.data.data?.communicationStats?.averageRecordingDUration
                });

                setCommunicationDistribution(response.data?.data?.callTypePercentages);
                setMemberResponseDistribution(response.data?.data?.memberResponseValues);
            } else {

            }
        } catch (e) {

        }finally{
            setIsLoading(false); 
        }
    }

    return (
        <>
            <LoaderLoader isLoading={isLoading} />

            <div className='container-fluid member-engagement tw-shadow-none'>

            <div className='row mb-3'>
                <div className='col-11 tw-px-1'>
                    <div className="form-group" style={{ width: "100%" }}>
                        <input  
                            type="text"
                            placeholder="Search By Member ID"
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                            className="form-control input-field-ui ps-5"
                        />
                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                    </div>
                </div>

                {/* <div className='col-md-5 px-1'>
                    <select
                        value={callTypeFilter}
                        onChange={e => setCallTypeFilter(e.target.value)}
                        className='form-select input-field-ui'
                    >
                        <option value="">--Select Status--</option>
                        {statusOptions[selectedChannel].map(option => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                        ))}

                    </select>
                </div> */}


                <div className='col-1'>
                    <button onClick={handleClear} className='btn tw-w-full clear-search-btn'>Clear</button>
                </div>  
                {/* <div className='col-md-auto px-1'>
                    <div className="dropdown">
                        <button className="btn btn-primary btn-primary clear-search-btn " type="button" onClick={(e) => fetchMemberCommunicationData('Y')}>
                            <i className="bi bi-download"></i> Export
                        </button>
                    </div>
                </div> */}
                </div>

                <div className="row text-center">
                    <div className="col-6 px-1" >
                        <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1" style={{fontSize: '26px'}}>{callStatsDataPoints.totalCalls || 0}</h1>
                                        <h5 className="card-text1" style={{fontSize: '14px'}}>Total Call Recordings 
                                            <OverlayTrigger placement="top" overlay={<Tooltip className="custom-tooltip">Total Number of Call Recordings</Tooltip>}>
                                                <i className='bx bx-info-circle ms-1'></i>
                                            </OverlayTrigger>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>      
                    </div>

                    <div className="col-6 px-1" >
                        <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1" style={{fontSize: '26px'}}>{callStatsDataPoints.averageRecordingDUration || 0}</h1>
                                        <h5 className="card-text1" style={{fontSize: '14px'}}>Average Call Duration
                                            <OverlayTrigger placement="top" overlay={<Tooltip className="custom-tooltip">Average time spent on each call by agents over time.</Tooltip>}>
                                                <i className='bx bx-info-circle ms-1'></i>
                                            </OverlayTrigger>
                                        </h5>   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* <div className="col-4 px-1" >
                        <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1" style={{fontSize: '26px'}}>{callStatsDataPoints.averageAttemptsPerMember}</h1>
                                        <h5 className="card-text1" style={{fontSize: '14px'}}>Average Attempt Per Member
                                            <OverlayTrigger placement="top" overlay={<Tooltip className="custom-tooltip">Number of closed / Rejected     observations</Tooltip>}>
                                                <i className='bx bx-info-circle ms-1'></i>
                                            </OverlayTrigger>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>

                {/* <div className="row text-center px-2 mb-3">
                    <div className='row mb-3'>
                            <div className="col-md-6 mb-3">
                                <div className="dashboard-graph-box mb-3">
                                    <h1 className='dashboard-graph-title '>
                                        <span>Call Status 
                                        <OverlayTrigger placement="top" overlay={<Tooltip className="custom-tooltip">Number of observations by status</Tooltip>}>
                                            <i className='bx bx-info-circle ms-1'></i>
                                        </OverlayTrigger>
                                        </span> 
                                    </h1>

                                    <CallStatusDistribution data={communicationDistribution} title={'Call Status'} />
                                    
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="dashboard-graph-box mb-3">
                                    <h1 className='dashboard-graph-title '>
                                        <span>Call Conversion 
                                        <OverlayTrigger placement="top" overlay={<Tooltip className="custom-tooltip">Number of observations by priority</Tooltip>}>
                                            <i className='bx bx-info-circle ms-1'></i>
                                        </OverlayTrigger>
                                        </span> 
                                    </h1>

                                    <CallStatusDistribution data={memberResponseDistribution} title={'Member Response'} />

                                </div>
                            </div>
                    </div>
                </div> */}

            </div>

            <ServerSideSortableDataTable
                columns={columns}
                data={data}
                paginationInfo={paginationConfig}
                sortConfig={sortConfig}
                updateSortConfig={updateSortConfig}
                setPerPage={setPerPage}
                setPaginationInfo={setPaginationConfig}
                isLoading={tableLoading}
            />



            <div className="modal fade" id="audioPopupModal" tabindex="1" aria-labelledby="audioPopupModal" data-backdrop='static' aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div class="modal-header">

                            <h6 class="modal-subtitle mb-2" id="exampleModalLabel">Agent Name:  {memberCallName}</h6>

                            <h6 class="modal-subtitle" id="exampleModalLabel">Member Name:  {agentCallName}</h6>

                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleStop} ></button>
                        </div>
                        <div class="modal-body mt-2">
                            <audio ref={audioRef} controls style={{ width: '100%' }}>
                                <source src={recordUrl} type="audio/mpeg" />
                                Your browser does not support the audio element.
                            </audio>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );


}

export default MemberCommunicationsTable;
